import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';
import {Palette, Theme, Button, Grid} from '@mui/material';

interface IOption {
    name: string;
    value: string;
    isActive: boolean;
    disabled?: boolean;
    uniformWidth: boolean;
    disableTextWrap: boolean;
    totalOptionsCount: number;
    onClick: (value: string) => void;
}

export const Option: FC<IOption> = ({
    name,
    value,
    onClick,
    disabled,
    isActive,
    uniformWidth,
    disableTextWrap,
    totalOptionsCount,
}) => {
    return (
        <Grid item xs={uniformWidth ? 12 / totalOptionsCount : 'auto'}>
            <Button
                fullWidth
                variant="text"
                color="inherit"
                disabled={disabled}
                onClick={() => !isActive && onClick(value)}
                sx={(theme: Theme) => ({
                    height: 40,
                    fontWeight: 600,
                    borderRadius: '99px',
                    textTransform: 'none',
                    textDecoration: 'none',
                    backgroundColor: isActive
                        ? theme.palette.labelPrimary.main
                        : theme.palette.backgroundTertiary.main,

                    '&:hover': {
                        textDecoration: 'none',
                        backgroundColor: isActive && theme.palette.labelPrimary.main,
                    },
                })}
                data-test-status={isActive}
            >
                <Text
                    semibold
                    variant="body2"
                    noWrap={!disableTextWrap}
                    color={(palette: Palette) =>
                        isActive ? palette.backgroundPrimary : palette.labelSecondary
                    }
                >
                    {name}
                </Text>
            </Button>
        </Grid>
    );
};
