import {ESubcategory as ESubcategoryLabel} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';
import {ESubCategory} from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';

export const SubCategoriesEnumsRecord: Record<ESubCategory, ESubcategoryLabel> = {
    [ESubCategory.FAMILY_HOUSE]: ESubcategoryLabel.FAMILY_HOUSE,
    [ESubCategory.COUNTRY_HOUSE]: ESubcategoryLabel.COUNTRY_HOUSE,
    [ESubCategory.MOBILE_HOUSE]: ESubcategoryLabel.MOBILE_HOUSE,
    [ESubCategory.HOUSEBOAT]: ESubcategoryLabel.HOUSEBOAT,
    [ESubCategory.OTHER_OBJECT_FOR_HOUSING]: ESubcategoryLabel.OTHER_TYPE_OF_OBJECT,

    [ESubCategory.CABIN_AND_LOG_CABIN]: ESubcategoryLabel.CABIN_AND_LOG_CABIN,
    [ESubCategory.COTTAGE_AND_RECREATION_HOUSE]: ESubcategoryLabel.COTTAGE_AND_RECREATION_HOUSE,
    [ESubCategory.GARDEN_HUT]: ESubcategoryLabel.GARDEN_HUT,
    [ESubCategory.OTHER_RECREATIONAL_OBJECT]: ESubcategoryLabel.OTHER_RECREATIONAL_OBJECT,

    [ESubCategory.LAND_FOR_FAMILY_HOUSE]: ESubcategoryLabel.LAND_FOR_FAMILY_HOUSE,
    [ESubCategory.RECREATIONAL_LAND]: ESubcategoryLabel.RECREATIONAL_LAND,
    [ESubCategory.LAND_FOR_HOUSING_CONSTRUCTION]: ESubcategoryLabel.LAND_FOR_HOUSING_CONSTRUCTION,
    [ESubCategory.LAND_FOR_CIVIC_AMENITIES]: ESubcategoryLabel.LAND_FOR_CIVIC_AMENITIES,
    [ESubCategory.COMMERCIAL_ZONE]: ESubcategoryLabel.COMMERCIAL_ZONE,
    [ESubCategory.INDUSTRIAL_ZONE]: ESubcategoryLabel.INDUSTRIAL_ZONE,
    [ESubCategory.MIXED_ZONE]: ESubcategoryLabel.MIXED_ZONE,
    [ESubCategory.GARDEN]: ESubcategoryLabel.GARDEN,
    [ESubCategory.ORCHARD]: ESubcategoryLabel.ORCHARD,
    [ESubCategory.VINEYARD_AND_HOP_GARDEN]: ESubcategoryLabel.VINEYARD_AND_HOP_GARDEN,
    [ESubCategory.MEADOW_AND_PASTURE]: ESubcategoryLabel.MEADOW_AND_PASTURE,
    [ESubCategory.ARABLE_LAND]: ESubcategoryLabel.ARABLE_LAND,
    [ESubCategory.FOREST_LAND]: ESubcategoryLabel.FOREST_LAND,
    [ESubCategory.POND_AND_LAKE]: ESubcategoryLabel.POND_AND_LAKE,
    [ESubCategory.LAND_FOR_ADVERTISING]: ESubcategoryLabel.LAND_FOR_ADVERTISING,
    [ESubCategory.OTHER_TYPE_OF_LAND]: ESubcategoryLabel.OTHER_TYPE_OF_LAND,
    [ESubCategory.OTHER_LAND_TO_BUILDING]: ESubcategoryLabel.OTHER_LAND_TO_BUILDING,
    [ESubCategory.OTHER_AGRICULTURAL_LAND]: ESubcategoryLabel.OTHER_AGRICULTURAL_LAND,
    [ESubCategory.FARM_SETTLEMENT]: ESubcategoryLabel.FARM_SETTLEMENT,

    [ESubCategory.OFFICES]: ESubcategoryLabel.OFFICES,
    [ESubCategory.BUSINESS_SPACES]: ESubcategoryLabel.BUSINESS_SPACES,
    [ESubCategory.RESTAURANT_SPACES]: ESubcategoryLabel.RESTAURANT_SPACES,
    [ESubCategory.STORAGE_AREAS]: ESubcategoryLabel.STORAGE_AREAS,
    [ESubCategory.SPACE_FOR_PRODUCTION]: ESubcategoryLabel.SPACE_FOR_PRODUCTION,
    [ESubCategory.REPAIR_AREA]: ESubcategoryLabel.REPAIR_AREA,
    [ESubCategory.SPORTS_FACILITIES]: ESubcategoryLabel.SPORTS_FACILITIES,
    [ESubCategory.SPACE_FOR_ADVERTISEMENT]: ESubcategoryLabel.SPACE_FOR_ADVERTISEMENT,
    [ESubCategory.OTHER_TYPE_OF_SPACE]: ESubcategoryLabel.OTHER_TYPE_OF_SPACE,

    [ESubCategory.RESTAURANT]: ESubcategoryLabel.RESTAURANT,
    [ESubCategory.DETACHED_GARAGE]: ESubcategoryLabel.DETACHED_GARAGE,
    [ESubCategory.HISTORICAL_OBJECT]: ESubcategoryLabel.HISTORICAL_OBJECT,
    [ESubCategory.SPORT_OBJECT]: ESubcategoryLabel.SPORT_OBJECT,
    [ESubCategory.MANUFACTURING_FACILITY]: ESubcategoryLabel.MANUFACTURING_FACILITY,
    [ESubCategory.HOTEL_AND_PENSION]: ESubcategoryLabel.HOTEL_AND_PENSION,
    [ESubCategory.COMMERCIAL_OBJECT]: ESubcategoryLabel.COMMERCIAL_OBJECT,
    [ESubCategory.WAREHOUSE]: ESubcategoryLabel.WAREHOUSE,
    [ESubCategory.POLYFUNCTIONAL_BUILDING]: ESubcategoryLabel.POLYFUNCTIONAL_BUILDING,
    [ESubCategory.OFFICE_BUILDING]: ESubcategoryLabel.OFFICE_BUILDING,
    [ESubCategory.OBJECT_FOR_BREEDING_ANIMALS]: ESubcategoryLabel.OBJECT_FOR_BREEDING_ANIMALS,
    [ESubCategory.RENTAL_HOUSE]: ESubcategoryLabel.RENTAL_HOUSE,
    [ESubCategory.COMMERCIAL_FACILITY]: ESubcategoryLabel.COMMERCIAL_FACILITY,
    [ESubCategory.AGRICULTURAL_OBJECT]: ESubcategoryLabel.OTHER_AGRICULTURAL_OBJECT,
    [ESubCategory.APARTMENT_HOUSE]: ESubcategoryLabel.APARTMENT_HOUSE,
    [ESubCategory.CONSOLIDATED_GROUND]: ESubcategoryLabel.CONSOLIDATED_GROUND,
    [ESubCategory.REPAIR_FACILITY]: ESubcategoryLabel.REPAIR_FACILITY,
    [ESubCategory.SPA]: ESubcategoryLabel.SPA,
    [ESubCategory.GAS_STATION]: ESubcategoryLabel.GAS_STATION,
    [ESubCategory.SMALL_POWER_STATION]: ESubcategoryLabel.SMALL_POWER_STATION,
    [ESubCategory.MOBILE_CELLS_AND_STANDS]: ESubcategoryLabel.MOBILE_CELLS_AND_STANDS,
    [ESubCategory.OTHER_TYPE_OF_OBJECT]: ESubcategoryLabel.OTHER_TYPE_OF_OBJECT,

    [ESubCategory.STUDIO_APARTMENT]: ESubcategoryLabel.STUDIO_APARTMENT,
    [ESubCategory.DOUBLE_STUDIO_APARTMENT]: ESubcategoryLabel.DOUBLE_STUDIO_APARTMENT,
    [ESubCategory.ONE_ROOM_APARTMENT]: ESubcategoryLabel.ONE_ROOM_APARTMENT,
    [ESubCategory.TWO_ROOM_APARTMENT]: ESubcategoryLabel.TWO_ROOM_APARTMENT,
    [ESubCategory.THREE_ROOM_APARTMENT]: ESubcategoryLabel.THREE_ROOM_APARTMENT,
    [ESubCategory.FOUR_ROOM_APARTMENT]: ESubcategoryLabel.FOUR_ROOM_APARTMENT,
    [ESubCategory.FIVE_PLUS_ROOM_APARTMENT]: ESubcategoryLabel.FIVE_PLUS_ROOM_APARTMENT,
    [ESubCategory.HOLIDAY_APARTMENT]: ESubcategoryLabel.HOLIDAY_APARTMENT,
    [ESubCategory.LOFT]: ESubcategoryLabel.LOFT,
    [ESubCategory.MAISONETTE]: ESubcategoryLabel.MAISONETTE,
    [ESubCategory.OTHER_TYPE_OF_APARTMENT]: ESubcategoryLabel.OTHER_TYPE_OF_APARTMENT,

    [ESubCategory.SECONDARY_STUDIO_APARTMENT]: ESubcategoryLabel.STUDIO_APARTMENT,
    [ESubCategory.SECONDARY_THREE_ROOM_APARTMENT]: ESubcategoryLabel.THREE_ROOM_APARTMENT,
    [ESubCategory.SECONDARY_LAND_FOR_FAMILY_HOUSES]: ESubcategoryLabel.LAND_FOR_FAMILY_HOUSE,
    [ESubCategory.SECONDARY_RECREATIONAL_LAND]: ESubcategoryLabel.RECREATIONAL_LAND,
    [ESubCategory.SECONDARY_COMMERCIAL_PLOTS]: ESubcategoryLabel.COMMERCIAL_PLOTS,
    [ESubCategory.SECONDARY_GARDEN]: ESubcategoryLabel.GARDEN,
    [ESubCategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS]: ESubcategoryLabel.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS,
    [ESubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND]: ESubcategoryLabel.SECONDARY_AGRICULTURAL_AND_FOREST_LAND,
    [ESubCategory.SECONDARY_GARAGE_AND_PARKING]: ESubcategoryLabel.SECONDARY_GARAGE_AND_PARKING,
    [ESubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION]: ESubcategoryLabel.SECONDARY_OFFICES_AND_ADMINISTRATION,
    [ESubCategory.SECONDARY_THE_SHOP]: ESubcategoryLabel.SECONDARY_THE_SHOP,
    [ESubCategory.SECONDARY_A_RESTAURANT]: ESubcategoryLabel.SECONDARY_A_RESTAURANT,
    [ESubCategory.SECONDARY_WAREHOUSES]: ESubcategoryLabel.SECONDARY_WAREHOUSES,
    [ESubCategory.SECONDARY_PRODUCTION]: ESubcategoryLabel.SECONDARY_PRODUCTION,
    [ESubCategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT]: ESubcategoryLabel.SECONDARY_ANOTHER_SPACE_AND_OBJECT,
    [ESubCategory.SECONDARY_HOTEL_GUESTHOUSE]: ESubcategoryLabel.SECONDARY_HOTEL_GUESTHOUSE,
};
