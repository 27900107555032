import { useState } from 'react';
import useLoading from 'modules/state/app/hook/useLoading';
import { fetchAdvertiserContacts } from 'components/page/advertisement/detail/api/advertiser/fetchAdvertiserContacts';

interface IReturn {
    isLoading: boolean;
    phoneNumber: string;
    fetchPhoneNumber: (token: string) => void;
}

export const useAdvertiserPhoneNumber = (id: string): IReturn => {
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
        undefined
    );

    const { start: startLoading, stop: stopLoading, isLoading } = useLoading();
    const fetchPhoneNumber = async (token: string) => {
        if (token && id) {
            try {
                startLoading();
                const data = await fetchAdvertiserContacts(id, token);
                stopLoading();
                if (data) {
                    setPhoneNumber(data);
                }
            } catch (e) {
                console.error(e);
            } finally {
                stopLoading();
            }
        }
    };

    return {
        isLoading,
        phoneNumber,
        fetchPhoneNumber,
    };
};
