import {EPageType} from 'modules/gtmEvents/enums/EPageType';
import {IAdvertisementItem} from 'modules/gtmEvents/interfaces/view/advertisement/partials/IAdvertisementItem';
import {getGtmLocationStringHelper, getItemVariant} from 'modules/gtmEvents/helpers/gtmHelper';
import {EItemBrand} from 'modules/gtmEvents/enums/EItemBrand';
import {EItemListId} from 'modules/gtmEvents/enums/EItemListId';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import {
    ECategory,
} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ECategory';
import {
    ESubcategory
} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';

export const getPageType = (devProjectId?: string, isPhotoGallery?: boolean): EPageType => {
    if (isPhotoGallery) {
        return EPageType.PHOTO_GALLERY;
    }
    if (devProjectId) {
        return EPageType.ADVERTISEMENT_PROJECT;
    }
    return EPageType.ADVERTISEMENT_DETAIL;
};

export const getAdvertisementItem = (advertisement: IAdvertisementGtmItem, index: number, itemIdPrefix: string, affiliation?: string, priceValue?: number): IAdvertisementItem => {
    const {
        photoUrl: mainPhotoUrl,
        videos,
        category,
        advertiser,
        transaction,
        realEstateState,
        price: advPrice,
        location: advLocation,
    } = advertisement;

    const location = getGtmLocationStringHelper(
        advLocation.country,
        advLocation.county,
        advLocation.district,
        advLocation.city,
    );

    const affiliationPrepare = [];
    if (advertiser.agency?.name) {
        affiliationPrepare.push(advertiser.agency?.name);
    }
    if (advertiser.name) {
        affiliationPrepare.push(advertiser.name);
    }

    let price = '';
    if (advPrice.priceValue) {
        price = advPrice.priceValue;
    }

    let item_category = 'undefined';
    if (category?.mainValue && transaction) {
        item_category = `${ECategory[category.mainValue]} ${(transaction).toLowerCase()}`;
    }

    let item_category2 = 'undefined';
    if (category?.subValue && transaction) {
        item_category2 = `${ESubcategory[category.subValue]} ${(transaction).toLowerCase()}`;
    }

    return {
        item_id: `${itemIdPrefix}_${advertisement.id}`,
        item_name: advertisement.title,
        affiliation: affiliation || affiliationPrepare.join(', '),
        index,
        item_brand: EItemBrand.CLASSIFIED_LISTING,
        item_category,
        item_category2,
        item_category3: location || 'undefined',
        item_category4: transaction || 'undefined',
        item_category5: realEstateState || 'undefined',
        item_list_id: EItemListId.ASSOCIATED_LISTINGS,
        item_list_name: EItemListId.ASSOCIATED_LISTINGS,
        item_variant: getItemVariant(mainPhotoUrl, videos.length > 0),
        location_id: location,
        price: priceValue ? priceValue : price,
        quantity: 1,
    };
};
