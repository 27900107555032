import {ReactNode} from 'react';
import {IGroup} from 'components/gallery/interfaces/group/IGroup';
import {useGallery} from 'components/gallery/hooks/useGallery';
import {EElementType} from 'components/gallery/enums/EElementType';
import {
    IPhoto
} from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/media/partials/photo/IPhoto';
import {
    IVideo
} from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/media/partials/video/IVideo';
import {
    IFloorPlan
} from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/media/partials/floorPlan/IFloorPlan';
import {
    IInspection3d
} from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/media/partials/Inspection3d/IInspection3d';
import {EGalleryGroupKey} from 'components/gallery/enums/group/EGalleryGroupKey';
import {getPhotoAlt} from 'components/page/advertisement/detail/helpers/photos/GetPhotoAlt';
import {EGalleryGroupName} from 'components/gallery/enums/group/EGalleryGroupName';
import {EGalleryGroupUrl} from 'components/gallery/enums/group/EGalleryGroupUrl';

interface IReturn {
    gallery: ReactNode;
    openGallery: (groupKey?: EGalleryGroupKey, itemIndex?: number) => void;
}

export const useDetailGallery = (
    isOpen: boolean,
    title: string,
    photos: IPhoto[],
    videos: IVideo[],
    advertisementId: string,
    floorPlans: IFloorPlan[],
    advertisementSlug: string,
    inspections3d: IInspection3d[],
    onOpenRedirectCallback: (groupKey: EGalleryGroupKey) => void,
    onCloseRedirectCallback: () => void,
    onChangeRedirectCallback: (groupKey: EGalleryGroupKey) => void,
    activeGroupKey: EGalleryGroupKey,
    gallerySideContent?: ReactNode,
): IReturn => {
    const groups: IGroup[] = [];

    if (photos.length) {
        groups.push({
            name: EGalleryGroupName.PHOTOS,
            url: EGalleryGroupUrl.PHOTOS,
            key: EGalleryGroupKey.PHOTOS,
            items: photos.map((photo, index) => ({
                element: {
                    type: EElementType.IMAGE,
                    url: photo.origUrl,
                    alt: getPhotoAlt(advertisementSlug, index + 1),
                },
                thumbmail: photo.smallUrl,
            })),
        });
    }

    if (videos.length) {
        groups.push({
            name: EGalleryGroupName.VIDEOS,
            url: EGalleryGroupUrl.VIDEOS,
            key: EGalleryGroupKey.VIDEOS,
            items: videos.map((video) => ({
                element: {
                    type: EElementType.IFRAME,
                    url: video.url,
                },
                thumbmail: video.url,
            })),
        });
    }

    if (inspections3d.length) {
        groups.push({
            name: EGalleryGroupName.INSPECTIONS_3D,
            url: EGalleryGroupUrl.INSPECTIONS_3D,
            key: EGalleryGroupKey.INSPECTIONS_3D,
            items: inspections3d.map((inspection3d) => ({
                element: {
                    type: EElementType.IFRAME,
                    url: inspection3d.url,
                },
                thumbmail: inspection3d.url,
            })),
        });
    }

    if (floorPlans.length) {
        groups.push({
            name: EGalleryGroupName.FLOOR_PLANS,
            url: EGalleryGroupUrl.FLOOR_PLANS,
            key: EGalleryGroupKey.FLOOR_PLANS,
            items: floorPlans.map((floorPlan) => ({
                element: {
                    type: EElementType.IMAGE,
                    url: floorPlan.url,
                },
                thumbmail: floorPlan.url,
            })),
        });
    }

    const { gallery, open: openGallery } = useGallery({
        isOpen,
        title,
        groups,
        onOpenRedirectCallback,
        onCloseRedirectCallback,
        advertisementId,
        advertisementSlug,
        gallerySideContent,
        activeGroupKey,
        onChangeRedirectCallback,
    });

    return {
        gallery,
        openGallery,
    };
};
