import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

interface IAgencyAddressText {
    addressPart: string;
}

export const AgencyAddressText: FC<IAgencyAddressText> = ({ addressPart }) => {
    return <Text variant="body2">{addressPart}</Text>;
};
