import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { ESubcategory } from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';
interface IAdvertisementInfo {
    price: string;
    location?: string;
    category: ESubcategory;
}

export const AdvertisementInfo: FC<IAdvertisementInfo> = ({
    category,
    location,
    price,
}) => {
    return <Box display='flex' flexDirection='column'>
        <Text variant='body2' semibold>{category} • {price}</Text>
        <Text
            variant='label1'
            color={(palette) => palette.labelSecondary}
        >
            {location}
        </Text>
    </Box>;
};