// Inspired by https://github.com/vercel/next.js/blob/v14.3.0-canary.87/packages/next/src/client/image-component.tsx

import { FC } from 'react';
import Head from 'next/head';
import ReactDOM from 'react-dom';
import { EImgWidth } from 'components/page/advertisement/detail/components/img/enums/EImgWidth';

type ImgProps = Omit<
    JSX.IntrinsicElements['img'],
    'src' | 'srcSet'
> & {
    srcSet: Partial<Record<EImgWidth, string>>;
    sizes?: string;
    priority?: boolean;
    fill?: boolean;
};

type ImgAttributes = Omit<ImgProps, 'priority' | 'srcSet'> & {
    src: string;
    srcSet: string;
};

const ImagePreload: FC<ImgAttributes> = ({
    sizes,
    srcSet,
    src,
    fetchPriority,
    crossOrigin,
    referrerPolicy,
}) => {
    const opts = {
        as: 'image',
        imageSrcSet: srcSet,
        imageSizes: sizes,
        crossOrigin,
        referrerPolicy,
        fetchPriority,
    };

    if (ReactDOM.preload) {
        // See https://github.com/facebook/react/pull/26940
        ReactDOM.preload(
            src,
            opts
        );
        return null;
    }

    return (
        <Head>
            <link
                key={'__nimg-' + src + srcSet + sizes}
                rel="preload"
                // Note how we omit the `href` attribute, as it would only be relevant
                // for browsers that do not support `imagesrcset`, and in those cases
                // it would cause the incorrect image to be preloaded.
                //
                // https://html.spec.whatwg.org/multipage/semantics.html#attr-link-imagesrcset
                href={srcSet ? undefined : src}
                {...opts}
            />
        </Head>
    );
};

// @see https://github.com/vercel/next.js/blob/v14.3.0-canary.87/packages/next/src/shared/lib/get-img-props.ts
function getImgAttributes({
    srcSet,
    priority,
    sizes,
    style,
    fill,
    ...rest
}: ImgProps): ImgAttributes {
    const imgStyle = Object.assign(
        fill
            ? {
                position: 'absolute',
                height: '100%',
                width: '100%',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
            }
            : {},
        style
    );

    const srcSetWidths = Object.keys(srcSet).map(Number).sort((a, b) => a - b);

    return {
        ...rest,
        fill,
        srcSet: srcSetWidths.map(width => `${srcSet[width]} ${width}w`).join(', '),
        src: srcSet[srcSetWidths[srcSetWidths?.length - 1]],
        sizes: sizes || '100vw',
        ...(priority && { fetchPriority: 'high' }),
        style: imgStyle,
    };
}

const ImgRender: FC<ImgAttributes> = ({ fetchPriority, fill, alt, ...rest }) => {
    return (
        <img
            {...rest}
            alt={alt}
            loading={!fetchPriority ? 'lazy' : undefined}
            data-nimg={fill ? 'fill' : '1'}
        />
    );
};

export const Img: FC<ImgProps> = (props) => {
    const attributes = getImgAttributes(props);
    return (
        <>
            {props.priority && <ImagePreload {...attributes} />}
            <ImgRender {...attributes} />
        </>
    );
};
