import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { TextArea } from 'modules/theme/components/fields/textArea/TextArea';
import { TextAreaLabel } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/common/textAreaLabel/TextAreaLabel';
import { IReportAdvertisement } from 'components/page/advertisement/detail/interfaces/reportAdvertisement/IReportAdvertisement';
import { Recaptcha } from 'components/recaptcha/components/Recaptcha';
import { TextareaCounter } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/common/content/partials/reportForm/common/TextareaCounter';

interface ISecondStep {
    values: IReportAdvertisement;
    touched: FormikTouched<IReportAdvertisement>;
    errors: FormikErrors<IReportAdvertisement>;
    setFieldValue: (
        field: string,
        value: string,
        shouldValidate?: boolean
    ) => void;
    disabled?: boolean;
}

const SecondStep: FC<ISecondStep> = ({
    values,
    touched,
    errors,
    setFieldValue,
    disabled,
}) => {
    return (
        <>
            <Grid item mb={1}>
                <TextAreaLabel />
            </Grid>
            <Grid item mb={3}>
                <TextArea
                    rows={5}
                    placeholder={'Inzerát má nevhodné foto.'}
                    disabled={disabled}
                    value={values.text}
                    errMsg={touched.text && errors.text}
                    onChange={(value) => setFieldValue('text', value)}
                />
                <TextareaCounter value={values.text}/>
            </Grid>
            <Grid item mb={2}>
                <Recaptcha
                    errorMsg={
                        touched.recaptchaToken && errors.recaptchaToken
                            ? errors.recaptchaToken
                            : undefined
                    }
                    onChange={(token) => setFieldValue('recaptchaToken', token)}
                />
            </Grid>
        </>
    );
};

export default SecondStep;
