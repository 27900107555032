import { FC, FunctionComponent, SVGProps } from 'react';
import { Grid } from '@mui/material';
import { Parameter } from 'components/page/advertisement/detail/components/mainContent/parameter/Parameter';

import DateIcon from 'modules/theme/components/icons/basic/Date.svg';
import ConstructionIcon from 'modules/theme/components/icons/technical/Construction.svg';
import NumberIcon from 'modules/theme/components/icons/basic/Number.svg';

interface IParameter {
    label?: string;
    value: string;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}

interface IDevProjectParameters {
    state: string;
    yearOfConstruction?: number;
    numberOfActiveAdvertisements: number;
}

export const DevProjectParameters: FC<IDevProjectParameters> = ({
    state,
    yearOfConstruction,
    numberOfActiveAdvertisements,
}) => {
    const parameters: IParameter[] = [];

    parameters.push({
        label: 'Počet aktívnych ponúk',
        value: `${numberOfActiveAdvertisements}`,
        icon: NumberIcon,
    });

    parameters.push({
        label: 'Stav projektu',
        value: state,
        icon: ConstructionIcon,
    });

    if (yearOfConstruction) {
        parameters.push({
            label: 'Rok výstavby',
            value: `${yearOfConstruction}`,
            icon: DateIcon,
        });
    }

    return (
        <Grid container spacing={{ md: 1 }}>
            {parameters.map((parameter) => (
                <Grid key={parameter.value} item xs={12}>
                    <Parameter
                        icon={parameter.icon}
                        label={parameter?.label}
                        value={parameter.value}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
