import { IAgency } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/advertiser/partials/agency/IAgency';
import {
    IGtmAgency
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/partials/advertiser/partials/agency/IGtmAgency';

export const mapIAgencyToIGtmAgency = (
    agency: IAgency
): IGtmAgency => ({
    id: agency.id,
    name: agency.name,
    slugName: agency.slugName,
});
