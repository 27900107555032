import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';

interface IMainPrice {
    value: string;
}

export const PowerCosts: FC<IMainPrice> = ({value}) => {
    return <Text variant="label2" semibold>
        + {value} energie
    </Text>;
};
