import { FC } from 'react';
import { CloseButton as CommonCloseButton } from 'components/page/advertisement/common/closeButton/CloseButton';
import { BackButton } from 'components/page/advertisement/detail/components/common/advertiser/common/backButton/BackButton';

interface ICloseButton {
    onClick: () => void;
    isOpenedFromAgentContactModal?: boolean;
}

export const CloseButton: FC<ICloseButton> = ({
    onClick,
    isOpenedFromAgentContactModal,
}) => {
    return isOpenedFromAgentContactModal ? (
        <BackButton onClick={onClick} text={'Kontaktné údaje'} />
    ) : (
        <CommonCloseButton onClick={onClick} />
    );
};
