import {FC} from 'react';
import {Theme, Box, Grid} from '@mui/material';

interface IWrapper {
    children?: JSX.Element;
}

export const Wrapper: FC<IWrapper> = ({children}) => {
    return (
        <Box display="flex" justifyContent="center" data-test-id='switchFilterWrapper'>
            <Grid
                container
                spacing={0.5}
                paddingRight={0.5}
                paddingBottom={0.5}
                borderRadius="99px"
                bgcolor={(theme: Theme) => theme.palette.backgroundTertiary.main}
                boxShadow={(theme: Theme) => `0px 2px 4px ${theme.palette.labelQuarternary.main} inset`}
            >
                {children}
            </Grid>
        </Box>
    );
};
