import * as Yup from 'yup';
import { ObjectShape, OptionalObjectSchema } from 'yup/lib/object';
import { StringSchema } from 'yup';
import { IReportAdvertisement } from 'components/page/advertisement/detail/interfaces/reportAdvertisement/IReportAdvertisement';
import { AdvertisementReportTypeEnum } from 'components/page/advertisement/detail/enums/reportAdvertisement/AdvertisementReportTypeEnum';
import { MAX_CHARACTERS_REPORT_MESSAGE } from 'components/page/advertisement/detail/constants/form';

export const getReportAdvertisementValidationSchema = (): OptionalObjectSchema<
    ObjectShape,
    Record<string, StringSchema>
> => {
    return Yup.object().shape<
        Record<keyof IReportAdvertisement, Yup.AnySchema>
    >({
        text: Yup.string().max(MAX_CHARACTERS_REPORT_MESSAGE, `Text musí byť kratší ako ${MAX_CHARACTERS_REPORT_MESSAGE} znakov`).optional(),
        type: Yup.mixed<AdvertisementReportTypeEnum>()
            .oneOf(Object.values(AdvertisementReportTypeEnum))
            .optional(),
        recaptchaToken: Yup.string().required('Je potrebné vyplniť reCAPTCHA'),
    });
};
