import {FC} from 'react';
import {Stack} from '@mui/material';
import Link from 'components/link/Link';
import {Svg} from 'modules/theme/components/svg/Svg';
import {Text} from 'modules/theme/components/text/Text';
import NextIcon from 'modules/theme/components/icons/basic/KeyboardArrowRight.svg';

interface IBreadcrumbItem {
    text: string;
    href?: string;
    isLast?: boolean;
}

export const BreadcrumbItem: FC<IBreadcrumbItem> = ({href, text, isLast}) => {

    return <Stack direction="row" gap={.5}>

        <Link href={href} disabled={isLast} sx={{'&:hover': {textDecoration: 'none'}}}>
            <Text variant="breadcrumb" color={(palette) => palette.labelSecondary} underLine={!isLast}>
                {text}
            </Text>
        </Link>

        {!isLast && <Svg icon component={NextIcon} width={16} color="disabled"/>}
    </Stack>;
};
