import { FC } from 'react';
import { IAttribute } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/parameters/partials/attribute/IAttribute';
import BulletIcon from 'modules/theme/components/icons/basic/Bullet.svg';
import { Parameter } from 'modules/theme/components/listing/parameter/components/Parameter';

interface IContent {
    attributes: IAttribute[];
}

export const Content: FC<IContent> = ({ attributes }) => {
    return <>
        {attributes.map((attribute, index) => (
            <Parameter
                isAttribute
                icon={BulletIcon}
                key={attribute.value}
                label={attribute.label}
                value={attribute.value}
                isHighlighted={attribute.isImportant}
                divider={index !== attributes.length - 1}
            />
        ))}
    </>;
};
