import {EGtmEvent} from 'modules/gtmEvents/enums/EGtmEvent';
import {
    getAdvertisementItem,
    getPageType
} from 'modules/gtmEvents/helpers/events/advertisement/common/partials/advertisementDataHelper';
import {EFormState} from 'modules/gtmEvents/enums/EFormState';
import {EFormStep} from 'modules/gtmEvents/enums/EFormStep';
import {EMethod} from 'modules/gtmEvents/enums/EMethod';
import {IGenerateLeadAdvertisementDetail} from 'modules/gtmEvents/interfaces/generateLead/IGenerateLeadAdvertisementDetail';
import {IPurchaseEvent} from 'modules/gtmEvents/interfaces/purchase/IPurchaseEvent';
import {ECurrency} from 'modules/gtmEvents/enums/ECurrency';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import {EItemListId} from 'modules/gtmEvents/enums/EItemListId';
import {getOrderIndex} from 'modules/gtmEvents/helpers/gtmHelper';
import {ECreativeSlot} from 'modules/gtmEvents/enums/ECreativeSlot';
import {EPromotionId} from 'modules/gtmEvents/enums/EPromotionId';
import {
    IAdvertisementViewPromotionEvent
} from 'modules/gtmEvents/interfaces/view/advertisement/IAdvertisementViewPromotionEvent';
import {ECreativeName} from 'modules/gtmEvents/enums/ECreativeName';
import {IAddToWishListEvent} from 'modules/gtmEvents/interfaces/wishList/IAddToWishListEvent';
import {
    IAdvertisementSelectItemEvent
} from 'modules/gtmEvents/interfaces/view/advertisement/IAdvertisementSelectItemEvent';
import {
    IAdvertisementSelectPromotionEvent
} from 'modules/gtmEvents/interfaces/view/advertisement/IAdvertisementSelectPromotionEvent';
import {AppConfigService} from 'modules/appConfig/AppConfigService';
import {IOnVisiblePhoneClickEvent} from 'modules/gtmEvents/interfaces/click/IOnVisiblePhoneClickEvent';
import {EPosition} from 'modules/gtmEvents/enums/advertisement/detail/EPosition';

export const getGenerateLeadEvent = (
    advertisementId: string,
    userId: string,
    userName: string,
    formState: EFormState,
    formStep: EFormStep,
    method: EMethod,
    position: EPosition,
    parentUserName?: string,
    devProjectId?: string,
    isLoggedIn?: boolean,
    isPhotoGallery?: boolean,
): IGenerateLeadAdvertisementDetail => {
    let affiliation = userName;
    if (parentUserName) {
        affiliation = `${affiliation}, ${parentUserName}`;
    }

    return {
        event: EGtmEvent.GENERATE_LEAD,
        method,
        form_state: formState,
        form_step: formStep,
        page_type: getPageType(devProjectId, isPhotoGallery),
        affiliation,
        affiliation_number: userId,
        affiliation_project: devProjectId,
        is_loggedin: isLoggedIn ? 'true' : 'undefined',
        position,
        item_id: advertisementId,
    };
};

export const getPurchaseEvent = (
    advertisement: IAdvertisementGtmItem,
    index: number,
    value?: number,
): IPurchaseEvent => {
    const purchaseValue = value ? value : 1;
    return {
        event: EGtmEvent.PURCHASE,
        ecommerce: {
            transaction_id: `${advertisement.id}_${new Date().getTime()}`,
            value: purchaseValue,
            currency: ECurrency.EUR,
            items: [
                getAdvertisementItem(advertisement, index, 'LT', null, purchaseValue),
            ]
        }
    };
};

export const getAddToCartEvent = (
    advertisement: IAdvertisementGtmItem,
    index: number,
    value?: number,
): IPurchaseEvent => {
    return {
        event: EGtmEvent.ADD_TO_CART,
        ecommerce: {
            transaction_id: `${advertisement.id}_${new Date().getTime()}`,
            value: value ? value : 1,
            currency: ECurrency.EUR,
            items: [
                getAdvertisementItem(advertisement, index, 'LT'),
            ]
        }
    };
};

export const getAdvertisementViewItemPromotionPremiumEvent = (creativeName: ECreativeName, advertisements: IAdvertisementGtmItem[], currentPage: number, limit: number): IAdvertisementViewPromotionEvent => {
    return {
        event: EGtmEvent.VIEW_PROMOTION,
        ecommerce: {
            creative_name: creativeName,
            creative_slot: ECreativeSlot.CAROUSEL,
            promotion_id: EPromotionId.PREMIUM_OF_LIST,
            promotion_name: EPromotionId.PREMIUM_OF_LIST,
            items: advertisements.map((advertisement, index) => {
                return getAdvertisementItem(advertisement, getOrderIndex(index, currentPage, limit), 'AD');
            }),
        }
    };
};

export const getAdvertisementViewItemPromotionTopEvent = (creativeName: ECreativeName, advertisements: IAdvertisementGtmItem[], currentPage: number, limit: number): IAdvertisementViewPromotionEvent => {
    return {
        event: EGtmEvent.VIEW_PROMOTION,
        ecommerce: {
            creative_name: creativeName,
            creative_slot: ECreativeSlot.CAROUSEL,
            promotion_id: EPromotionId.TOP_OF_LIST,
            promotion_name: EPromotionId.TOP_OF_LIST,
            items: advertisements.map((advertisement, index) => {
                return getAdvertisementItem(advertisement, getOrderIndex(index, currentPage, limit), 'AD');
            }),
        }
    };
};

export const getAdvertisementAddToWishListEvent = (advertisement: IAdvertisementGtmItem): IAddToWishListEvent => {
    return {
        event: EGtmEvent.ADD_TO_WISHLIST,
        ecommerce: {
            currency: ECurrency.EUR,
            value: advertisement.price.priceValue,
            items: [
                getAdvertisementItem(advertisement, getOrderIndex(0, 1, 1), 'LT')
            ]
        }
    };
};

export const getAdvertisementSelectItemEvent = (itemList: EItemListId, advertisement: IAdvertisementGtmItem, index: number): IAdvertisementSelectItemEvent => {
    return {
        event: EGtmEvent.SELECT_ITEM,
        ecommerce: {
            item_list_id: itemList,
            item_list_name: itemList,
            items: [
                getAdvertisementItem(advertisement, index, 'LT', AppConfigService.getTitle()),
            ],
        }
    };
};

export const getAdvertisementSelectPromotionItemEvent = (creativeName: ECreativeName, advertisement: IAdvertisementGtmItem, index: number): IAdvertisementSelectPromotionEvent => {
    let promotion: EPromotionId;
    if (advertisement.isPremium) {
        promotion = EPromotionId.PREMIUM_OF_LIST;
    }
    if (advertisement.isTop) {
        promotion = EPromotionId.TOP_OF_LIST;
    }

    return {
        event: EGtmEvent.SELECT_PROMOTION,
        ecommerce: {
            creative_name: creativeName,
            creative_slot: ECreativeSlot.CAROUSEL,
            promotion_id: promotion,
            promotion_name: promotion,
            items: [
                getAdvertisementItem(advertisement, index, 'LT', AppConfigService.getTitle()),
            ],
        }
    };
};

export const getClickPhoneNumberEvent = (position: EPosition, devProjectId?: string, isPhotoGallery?: boolean): IOnVisiblePhoneClickEvent => {
    return {
        event: EGtmEvent.CLICK_ON_VISIBLE_PHONE_NUMBER,
        position,
        page_type: getPageType(devProjectId, isPhotoGallery),
    };
};

