import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

interface IAgencyName {
    name?: string;
}

export const AgencyName: FC<IAgencyName> = ({ name }) => {
    return (
        <Text variant="h4" semibold>
            {name}
        </Text>
    );
};
