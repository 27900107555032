import * as Yup from 'yup';
import { ObjectShape, OptionalObjectSchema } from 'yup/lib/object';
import { StringSchema } from 'yup';
import { IContactAdvertiser } from 'components/page/advertisement/detail/interfaces/advertiser/IContactAdvertiser';
import { IContactAdvertiserValidationSchemaOptions } from 'components/page/advertisement/detail/interfaces/advertiser/IContactAdvertiserValidationSchemaOptions';
import { AdvertiserContactTypeEnum } from 'components/page/advertisement/detail/enums/advertiser/AdvertiserContactTypeEnum';

const schemaOptions: IContactAdvertiserValidationSchemaOptions = {
    required: 'Povinné',
    gdprRequired: 'Súhlas je povinný',
    emailNotValid: 'Opravte prosím: Váš e-mail',
    phoneNotValid: 'Opravte prosím: Telefón',
};

export const getContactAdvertiserValidationSchema = (): OptionalObjectSchema<
    ObjectShape,
    Record<string, StringSchema>
> => {
    const phoneSchema = (requiredMessage: string, formatMessage: string) =>
        Yup.string()
            .required(requiredMessage)
            .test('mobile', formatMessage, function (value) {
                if (value) {
                    const patterns = [
                        '^(02)(/)?([0-9]{8})$',
                        '^(0[3-5][1-9])(/)?([0-9]{7})$',
                        '^(?:\\+421|0|00421)(?:[0-9]{9})$',
                        '^(?:\\+420|00420)(?:[0-9]{9})$',
                        '^(?:\\+43|0|0043)(?:[0-9]{10,11})$', // Austrian phone number regex
                    ];

                    value = value.replace(/\s/g, '');
                    for (const pattern of patterns) {
                        const regex = new RegExp(pattern);
                        if (regex.test(value)) {
                            return true;
                        }
                    }
                }
                return false;
            });

    return Yup.object().shape<Record<keyof IContactAdvertiser, Yup.AnySchema>>({
        text: Yup.string().required(schemaOptions.required),
        mail: Yup.string()
            .email(schemaOptions.emailNotValid)
            .required(schemaOptions.required),
        phoneNumber: phoneSchema(
            schemaOptions.required,
            schemaOptions.phoneNotValid
        ),
        name: Yup.string().required(schemaOptions.required),
        type: Yup.mixed<AdvertiserContactTypeEnum>()
            .oneOf(Object.values(AdvertiserContactTypeEnum))
            .required(schemaOptions.required),
        gdpr: Yup.boolean().oneOf([true], schemaOptions.gdprRequired),
        recaptchaToken: Yup.string().required('Je potrebné vyplniť reCAPTCHA'),
    });
};
