import { FC, MouseEventHandler } from 'react';
import { Box } from '@mui/material';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';
import { Svg } from 'modules/theme/components/svg/Svg';

interface ICloseButton {
    onClick: MouseEventHandler<HTMLElement>;
}

export const CloseButton: FC<ICloseButton> = ({onClick}) => {
    return <Box
        top={24}
        left={24}
        onClick={onClick}
        position='absolute'
        sx={(theme) => ({
            color: theme.palette.labelPrimaryInverse.main,
            cursor: 'pointer'
        })}
    >
        <Svg icon width={24} component={CloseIcon}/>
    </Box>;
};