import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

interface IGalleryWrapper {
    children: ReactNode;
}

export const GalleryWrapper: FC<IGalleryWrapper> = ({children}) => {

    return <Box
        sx={(theme) => ({
            height: '100%',
            '& .image-gallery-index': {
                left: '50%',
                display: 'flex',
                width: 'fit-content',
                justifyContent: 'center',
                fontSize: theme.typography.body2,
                transform: 'translate(-50%, 24px)',
            },
            '& .image-gallery': {
                '&.fullscreen-modal': {
                    zIndex: '1500!important',
                },
                height: '100%',
            },
            '& .image-gallery-content': {
                height: '100%',
                display: 'flex',
                backgroundColor: 'black',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
            },
            '& .image-gallery-thumbnails-wrapper': {
                marginBottom: 2,
            },
            '& .image-gallery-thumbnail-image': {
                objectFit: 'cover',
                maxHeight: 50,
            },
            '& .image-gallery-slide-wrapper': {
                width: '100%',
                height: '100%',
            },
            '& .image-gallery-swipe': {
                height: '100%',
            },
            '& .image-gallery-slides': {
                height: '100%',
            },
            '& .image-gallery-slide': {
                height: '100%',
                '& > div': {
                    height: '100%',
                }
            }
        })}
    >
        {children}
    </Box>;

};