import { FC } from 'react';
import { Box } from '@mui/material';
import DesktopModal from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/desktop/DesktopModal';
import MobileModal from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/mobile/MobileModal';
import { Desktop, Mobile } from 'modules/theme/components/responsive';

interface IReportButton {
    onClose: () => void;
    id: string;
}

export const Modal: FC<IReportButton> = ({ onClose, id }) => {

    return (
        <Box>
            <Mobile>
                <MobileModal id={id} onClose={onClose} />
            </Mobile>
            <Desktop>
                <DesktopModal id={id} onClose={onClose} />
            </Desktop>
        </Box>
    );
};
