import {Stack} from '@mui/material';
import {Svg} from 'modules/theme/components/svg/Svg';
import {FC, FunctionComponent, SVGProps} from 'react';
import {Text} from 'modules/theme/components/text/Text';


interface IParameter {
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    label?: string;
    value: string;
}

export const Parameter: FC<IParameter> = ({icon, label, value}) => {
    return <Stack direction="row" gap={1} py={1}>
        <Svg icon component={icon} width={24}/>

        <Stack direction="row" gap={.4}>
            {label && <Text variant="body2">{label}: </Text>}
            <Text variant="body2">{value}</Text>
        </Stack>

    </Stack>;
};
