import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import ArrowBackIcon from 'modules/theme/components/icons/basic/ArrowBack.svg';
import { Text } from 'modules/theme/components/text/Text';

interface IBackButton {
    onBackClick: () => void;
}

export const BackButton: FC<IBackButton> = ({ onBackClick }) => {
    return (
        <Box onClick={() => onBackClick()} sx={{ cursor: 'pointer' }}>
            <Stack flexDirection={'row'} rowGap={0.5} alignItems={'center'}>
                <Svg icon component={ArrowBackIcon} />
                <Text variant={'body3'}>{'Späť'}</Text>
            </Stack>
        </Box>
    );
};
