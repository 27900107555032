import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';

export const AREA_LABELS: Record<EMainCategory, string> = {
    [EMainCategory.REAL_ESTATES]: 'Plocha',
    [EMainCategory.APARTMENTS]: 'Plocha bytu',
    [EMainCategory.HOUSES]: 'Plocha domu',
    [EMainCategory.SECONDARY_HOUSES]: 'Plocha domu',
    [EMainCategory.COTTAGES_AND_CABINS]: 'Plocha chalupy',
    [EMainCategory.LANDS]: 'Plocha pozemku',
    [EMainCategory.SECONDARY_LANDS]: 'Plocha pozemku',
    [EMainCategory.SPACES]: 'Plocha priestoru',
    [EMainCategory.OBJECTS]: 'Plocha objektu',
    [EMainCategory.SECONDARY_SPACES_AND_OBJECTS]: 'Plocha objektu',
    [EMainCategory.SECONDARY_RECREATIONAL_PROPERTIES]: 'Plocha chalupy',
};
