import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import useDialog from 'modules/dialog/useDialog';
import { IAttribute } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/parameters/partials/attribute/IAttribute';
import { AttributesModal } from 'components/page/advertisement/detail/components/mainContent/detailParameters/partials/realEstateProperties/modal/Modal';

interface IShowMore {
    attributes: IAttribute[];
}

export const ShowMore: FC<IShowMore> = ({ attributes }) => {
    const {
        component: modal,
        show: showDialog,
        hide: hideDialog,
    } = useDialog({
        content: (
            <AttributesModal
                title={'Vlastnosti nehnuteľnosti'}
                attributes={attributes}
                onClose={() => hideDialog()}
            />
        ),
        paperProps: {
            sx: {
                width: 560,
                borderRadius: (theme) => theme.spacing(3),
            },
        },
    });

    return (
        <>
            <Box onClick={() => showDialog()} sx={{ cursor: 'pointer' }}>
                <Text variant="body2" noWrap underLine>
                    Zobraziť všetky
                </Text>
            </Box>
            {modal}
        </>
    );
};
