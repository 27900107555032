import { FC } from 'react';
import { CloseButton } from 'components/page/advertisement/common/closeButton/CloseButton';

interface IHeader {
    onClick: () => void;
}

export const Header: FC<IHeader> = ({ onClick }) => {
    return <CloseButton onClick={onClick} />;
};
