import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

interface ITitle {
    text: string;
}

export const Title: FC<ITitle> = ({ text }) => {
    return <Text variant={'body3'}>{text}</Text>;
};
