import {EGtmEvent} from 'modules/gtmEvents/enums/EGtmEvent';
import {
    getAdvertisementItem,
} from 'modules/gtmEvents/helpers/events/advertisement/common/partials/advertisementDataHelper';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import {IAdvertisementViewItemEvent} from 'modules/gtmEvents/interfaces/view/advertisement/IAdvertisementViewItemEvent';
import {ECurrency} from 'modules/gtmEvents/enums/ECurrency';
import {
    IAdvertisementViewItemListEvent
} from 'modules/gtmEvents/interfaces/view/advertisement/IAdvertisementViewItemListEvent';
import {EItemListId} from 'modules/gtmEvents/enums/EItemListId';
import {getOrderIndex} from 'modules/gtmEvents/helpers/gtmHelper';
import {IClickEvent} from 'modules/gtmEvents/interfaces/click/IClickEvent';
import {EMethod} from 'modules/gtmEvents/enums/EMethod';
import {ITrackEvent} from 'modules/gtmEvents/interfaces/click/ITrackEvent';
import {ETrackEventCategory} from 'modules/gtmEvents/enums/ETrackEventCategory';

export const getAdvertisementViewItemEvent = (advertisement: IAdvertisementGtmItem, index: number): IAdvertisementViewItemEvent => {
    return {
        event: EGtmEvent.VIEW_ITEM,
        ecommerce: {
            item_list_id: EItemListId.CLASSIFIED_SEARCH_RESULTS,
            item_list_name: EItemListId.CLASSIFIED_SEARCH_RESULTS,
            currency: ECurrency.EUR,
            value: advertisement.price.priceValue || 'undefined',
            items: [
                getAdvertisementItem(advertisement, index, 'LT'),
            ],
        }
    };
};

export const getAdvertisementsViewItemListSimilarFromSellerEvent = (advertisements: IAdvertisementGtmItem[], currentPage: number, limit: number): IAdvertisementViewItemListEvent => {
    return {
        event: EGtmEvent.VIEW_ITEM_LIST,
        ecommerce: {
            item_list_id: EItemListId.SIMILAR_LISTINGS_FROM_SELLER,
            item_list_name: EItemListId.SIMILAR_LISTINGS_FROM_SELLER,
            items: advertisements.map((advertisement, index) => {
                return getAdvertisementItem(advertisement, getOrderIndex(index, currentPage, limit), 'AD');
            }),
        }
    };
};

export const getAdvertisementsViewItemListSimilarEvent = (advertisements: IAdvertisementGtmItem[], currentPage: number, limit: number): IAdvertisementViewItemListEvent => {
    return {
        event: EGtmEvent.VIEW_ITEM_LIST,
        ecommerce: {
            item_list_id: EItemListId.SIMILAR_LISTINGS,
            item_list_name: EItemListId.SIMILAR_LISTINGS,
            items: advertisements.map((advertisement, index) => {
                return getAdvertisementItem(advertisement, getOrderIndex(index, currentPage, limit), 'AD');
            }),
        }
    };
};

export const getAdvertisementsViewItemListExclusiveEvent = (advertisements: IAdvertisementGtmItem[], currentPage: number, limit: number): IAdvertisementViewItemListEvent => {
    return {
        event: EGtmEvent.VIEW_ITEM_LIST,
        ecommerce: {
            item_list_id: EItemListId.EXCLUSIVE_LISTINGS,
            item_list_name: EItemListId.EXCLUSIVE_LISTINGS,
            items: advertisements.map((advertisement, index) => {
                return getAdvertisementItem(advertisement, getOrderIndex(index, currentPage, limit), 'AD');
            }),
        }
    };
};

export const getClickAgencyInfoEvent = (
    advertisementId: string,
    isLoggedIn: boolean,
): IClickEvent => {
    return {
        event: EGtmEvent.AGENCY_INFO_CLICK,
        is_loggedin: isLoggedIn ? 'true' : 'undefined',
        item_id: `LT_${advertisementId}`,
    };
};

export const getClickAgentInfoEvent = (
    advertisementId: string,
    isLoggedIn: boolean,
): IClickEvent => {
    return {
        event: EGtmEvent.AGENT_INFO_CLICK,
        is_loggedin: isLoggedIn ? 'true' : 'undefined',
        item_id: `LT_${advertisementId}`,
    };
};

export const getClick3dVideoEvent = (
    advertisementId: string,
    isLoggedIn: boolean,
): IClickEvent => {
    return {
        event: EGtmEvent.THREE_D_VIDEO,
        method: EMethod.SHOW_3D_VIDEO,
        is_loggedin: isLoggedIn ? 'true' : 'undefined',
        item_id: `LT_${advertisementId}`,
    };
};

export const getClickMapEvent = (
    advertisementId: string,
    isLoggedIn: boolean,
): IClickEvent => {
    return {
        event: EGtmEvent.MAP,
        method: EMethod.SHOW_MAP,
        is_loggedin: isLoggedIn ? 'true' : 'undefined',
        item_id: `LT_${advertisementId}`,
    };
};

export const getClickVideoEvent = (
    advertisementId: string,
    isLoggedIn: boolean,
): IClickEvent => {
    return {
        event: EGtmEvent.VIDEO,
        method: EMethod.SHOW_VIDEO,
        is_loggedin: isLoggedIn ? 'true' : 'undefined',
        item_id: `LT_${advertisementId}`,
    };
};

export const getTrackEvent = (
    action: string,
    mainCategory: string,
    isDevProject: boolean,
): ITrackEvent => {
    return {
        event: EGtmEvent.TRACK_EVENT,
        category: isDevProject ? ETrackEventCategory.DETAIL_ADVERTISEMENT_PROJECT : ETrackEventCategory.DETAIL_ADVERTISEMENT,
        action,
        label: mainCategory,
    };
};
