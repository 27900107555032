import { FC, useEffect, useState } from 'react';
import { Box, useMediaQuery, Theme } from '@mui/material';
import { EElementType } from 'components/gallery/enums/EElementType';
import { IElement } from 'components/gallery/interfaces/group/partials/item/partials/element/IElement';
import Image from 'components/image/Image';
interface IGalleryElement {
    element: IElement;
    onClick?: () => void;
    isFullscreen: boolean;
}

export const GalleryElement: FC<IGalleryElement> = ({
    element,
    onClick,
    isFullscreen
}) => {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const [windowSize, setWindowSize] = useState({ width: 0, height: 0});

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        }

        const handleWindowResize = () => {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const width = isFullscreen && isMobile ? windowSize.width : isFullscreen ? windowSize.width - 200 : 1440;
    const height = !isFullscreen ? 576 : isMobile ? windowSize.height : windowSize.height - 160;
    const pointerEvents = isFullscreen ? 'initial' : 'none';

    const isImage = element.type === EElementType.IMAGE;
    const isIFrame = element.type === EElementType.IFRAME;

    return <>
        {isImage && (
            <Box
                display={'flex'}
                flexDirection={'row-reverse'}
                width={'100%'}
                height={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box
                    position={'relative'}
                    height={'fit-content'}
                >
                    <Image
                        width={width}
                        height={height}
                        src={element.url}
                        onClick={onClick}
                        alt={element.alt || 'image'}
                        style={{
                            width: !isFullscreen && '100%',
                            height: !isFullscreen && '100%',
                            borderRadius: !isFullscreen && 8,
                            objectFit: isFullscreen ? 'contain' : 'cover'
                        }}
                    />
                </Box>
            </Box>
        ) }

        {isIFrame &&
            <Box
                width={'100%'}
                maxWidth={'100%'}
                height={isFullscreen && isMobile ? 260 : isFullscreen ? 650 : 500}
                marginBottom={isFullscreen ? 0 : 1 }
                onClick={onClick}
            >
                <iframe
                    width='100%'
                    height='100%'
                    allowFullScreen
                    src={element.url}
                    style={{
                        pointerEvents,
                        border: 'none',
                        borderRadius: !isFullscreen && 8,
                    }}
                />
            </Box>
        }
    </>;
};
