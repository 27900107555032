import {FC} from 'react';
import {OverridableStringUnion} from '@mui/types';
import {Chip as MuiChip, useTheme} from '@mui/material';
import {ChipPropsSizeOverrides} from '@mui/material/Chip/Chip';

interface IChip {
    text: string;
    small?: boolean;
    medium?: boolean;
    isActive?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    clickable?: boolean;
}

export const Chip: FC<IChip> = ({
    text,
    small,
    medium,
    onClick,
    isActive,
    disabled,
    clickable,
}) => {
    if (small && medium) {
        throw Error('You can use small and medium at same time. ');
    }

    const {palette} = useTheme();

    const activeStyle = {
        color: palette.backgroundPrimary.main,
        borderColor: palette.labelPrimary.main,
        backgroundColor: palette.labelPrimary.main,
    };

    let size: OverridableStringUnion<'small' | 'medium' | 'normal',
        ChipPropsSizeOverrides>;
    if (small) size = 'small';
    if (medium) size = 'medium';

    return (
        <MuiChip
            size={size}
            label={text}
            onClick={onClick}
            disabled={disabled}
            sx={isActive && { ...activeStyle }}
            data-test-id="chip"
            clickable={clickable}
        />
    );
};
