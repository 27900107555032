import {FC, ReactNode} from 'react';
import { Dialog } from '@mui/material';
import { GalleryAppBar } from 'components/gallery/components/partials/dialog/partials/appBar/AppBar';
import { GalleryItems } from 'components/gallery/components/partials/dialog/partials/items/Items';
import { IItem } from 'components/gallery/interfaces/group/partials/item/IItem';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';
import { IGroup } from 'components/gallery/interfaces/group/IGroup';

interface IGalleryDialog {
    title: string;
    isOpen: boolean;
    groups: IGroup[];
    onClose: () => void;
    activeItems: IItem[];
    activeGroupKey: EGalleryGroupKey;
    gallerySideContent?: ReactNode,
    isFullscreenGalleryOpen: boolean;
    onActiveItemIndexChanged: (index: number) => void;
    onActiveGroupKeyChanged: (index: EGalleryGroupKey) => void;
}

export const GalleryDialog: FC<IGalleryDialog> = ({
    title,
    isOpen,
    groups,
    onClose,
    activeItems,
    activeGroupKey,
    gallerySideContent,
    isFullscreenGalleryOpen,
    onActiveGroupKeyChanged,
    onActiveItemIndexChanged,
}) => {

    return <Dialog
        open={isOpen}
        fullScreen={true}
    >
        <GalleryAppBar title={title} onClose={onClose}/>

        <GalleryItems
            groups={groups}
            activeItems={activeItems}
            activeGroupKey={activeGroupKey}
            sideContent={gallerySideContent}
            isFullscreenGalleryOpen={isFullscreenGalleryOpen}
            onActiveGroupKeyChanged={onActiveGroupKeyChanged}
            onActiveItemIndexChanged={onActiveItemIndexChanged}
        />
    </Dialog>;
};
