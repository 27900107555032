import {Stack} from '@mui/material';
import {FC, FunctionComponent, ReactNode, SVGProps} from 'react';
import {Cta as PromoCtaButton} from 'components/page/advertisement/detail/components/mainContent/promoBanner/common/partials/Cta';
import {Text as PromoBannerText} from 'components/page/advertisement/detail/components/mainContent/promoBanner/common/partials/Text';
import { SvgIconColor } from 'modules/theme/components/svg/Svg';

interface IPromoBanner {
    text: string;
    ctaText: ReactNode;
    iconColor?: SvgIconColor;
    iconBgColor: string;
    ctaSubpageHref: string;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    onClick?: () => void;
}

export const PromoBanner: FC<IPromoBanner> = ({icon, text, ctaText, ctaSubpageHref, iconBgColor, iconColor, onClick}) => {
    return <Stack direction="row" py={3} gap={2} alignItems="center" justifyContent="space-between">
        <PromoBannerText text={text} icon={icon} iconBgColor={iconBgColor} iconColor={iconColor}/>
        <PromoCtaButton text={ctaText} subpage={ctaSubpageHref} onClick={onClick}/>
    </Stack>;
};
