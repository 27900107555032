import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import Link from 'components/link/Link';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { ERouterBranchType } from 'modules/route/enums/agency/detail/ERouterBranchType';
import { ERouterTabs } from 'modules/route/enums/agency/detail/ERouterTabs';
import { REAL_ESTATE_BRANCH_DETAIL_TAB } from 'modules/route/routes';
import { Firm } from 'modules/theme/components/assets/userBudges/firm/Firm';
import { IAgency } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/advertiser/partials/agency/IAgency';
import { IBranch } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/advertiser/partials/branch/IBranch';
import { AgencyInfo } from 'components/page/advertisement/detail/components/common/advertiser/common/agencyProfile/partials/agencyInfo/AgencyInfo';
import { AdvertiserProfileTitle } from 'components/page/advertisement/detail/components/common/advertiser/common/advertiserProfileTitle/AdvertiserProfileTitle';
import useSession from 'modules/state/app/hook/session/useSession';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import {
    getClickAgencyInfoEvent,
} from 'modules/gtmEvents/helpers/events/advertisement/detail/advertisementDetailEventsHelper';
import { ProfiBadge } from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/photoPart/preview/partials/badges/partials/bottom/partials/ProfiBadge';

interface IAgencyProfile {
    agency?: IAgency;
    branch?: IBranch;
    advertisementId: string;
}

export const AgencyProfile: FC<IAgencyProfile> = ({ agency, branch, advertisementId }) => {
    const { session } = useSession();
    const isAdvertiserLoggedIn = !!session;

    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();

    let name = agency?.name;
    let location = agency?.location;
    let advertiserProfileTitle = 'Profil realitnej kancelárie';
    let logoUrl = agency?.logoUrl;
    let branchDetailUrl = `${AppConfigService.getNehnutelnostiUrl()}${REAL_ESTATE_BRANCH_DETAIL_TAB(
        agency?.id,
        agency?.slugName,
        ERouterBranchType.CENTRAL_BRANCH,
        ERouterTabs.ABOUT
    )}`;

    if (branch) {
        name = branch?.name;
        location = branch?.location;
        advertiserProfileTitle = 'Profil pobočky';
        branchDetailUrl = `${AppConfigService.getNehnutelnostiUrl()}${REAL_ESTATE_BRANCH_DETAIL_TAB(
            branch?.id,
            branch?.slugName,
            ERouterBranchType.BRANCH,
            ERouterTabs.ABOUT
        )}`;
        logoUrl = branch.logoUrl || logoUrl;
    }

    return (
        <Stack flexDirection={'row'} columnGap={1}>
            <Box width={'100%'}>
                <Box mb={2}>
                    <AgencyInfo name={name} location={location} />
                </Box>
                <Link href={branchDetailUrl} noUnderline onClick={() => {
                    gtmSendEvent(
                        getClickAgencyInfoEvent(advertisementId, isAdvertiserLoggedIn),
                    );
                }}>
                    <AdvertiserProfileTitle
                        title={advertiserProfileTitle}
                    />
                </Link>
            </Box>
            <Box display={'flex'} flexDirection={'column'} alignItems={'end'}
                gap={{ xs: '8px', md: '12px' }}
            >
                <Firm width={90} height={60} logoUrl={logoUrl} />
                {(agency?.isProfi) && (
                    <ProfiBadge />
                )}

            </Box>
        </Stack>
    );
};
