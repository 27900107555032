import {FC} from 'react';
import {Svg} from 'modules/theme/components/svg/Svg';
import Icon from 'modules/theme/components/assets/noPhoto/svg/FirmAvatar.svg';

interface IFirmNoPhoto {
    width: number | string;
    height?: number | string;
}

export const FirmNoPhoto: FC<IFirmNoPhoto> = ({width, height}) => {
    return <Svg icon component={Icon} width={width} height={height}/>;
};
