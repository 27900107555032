import { FC } from 'react';
import { Box, Palette, Stack } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import { useAdvertiserPhoneNumber } from 'components/page/advertisement/detail/hooks/advertiser/useAdvertiserPhoneNumber';
import {
    getClickPhoneNumberEvent,
    getGenerateLeadEvent, getPurchaseEvent
} from 'modules/gtmEvents/helpers/events/advertisement/common/advertisementEventsHelper';
import {EPosition} from 'modules/gtmEvents/enums/advertisement/detail/EPosition';
import {EFormState} from 'modules/gtmEvents/enums/EFormState';
import {EFormStep} from 'modules/gtmEvents/enums/EFormStep';
import {EMethod} from 'modules/gtmEvents/enums/EMethod';
import {
    getResetEcommerceEvent
} from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import {
    IAdvertiser
} from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/advertiser/IAdvertiser';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import useSession from 'modules/state/app/hook/session/useSession';
import {IGtmEvent} from 'modules/analytics/IAnalytics';

interface IPhone {
    phoneNum: string;
    isMobile: boolean;
    advertiserId: string;
    advertisementId: string;
    advertiser: IAdvertiser;
    advertisementGtmItem: IAdvertisementGtmItem;
}

export const Phone: FC<IPhone> = ({ advertiserId, phoneNum, advertisementId, advertiser, advertisementGtmItem, isMobile }) => {
    const { session } = useSession();
    const isAdvertiserLoggedIn = !!session;

    const { phoneNumber, fetchPhoneNumber } =
        useAdvertiserPhoneNumber(advertiserId);

    const {
        gtm: { sendEvent: gtmSendEvent },
        livemonitor: { sendEvent: livemonitorSendEvent },
    } = useAnalytics();

    const isAfterFetch = !!phoneNumber;
    const isThereAphoneNumber = phoneNum && phoneNum.length;
    const abbreviatedPhoneNumber = isThereAphoneNumber ? phoneNum.substring(0, 5) + ' ...' : ' ';

    const finalNumber = phoneNumber
        ? phoneNumber
        : abbreviatedPhoneNumber;

    const onPhoneNumClick = () => {
        if (!isAfterFetch) {
            fetchPhoneNumber('token');

            let formState = EFormState.SUCCESS;
            let method = EMethod.SRL_SHOW_PHONE_NUMBER;
            if (isMobile) {
                method = EMethod.SQL_CLICK_TO_CALL;
                formState = EFormState.PROGRESSED;
            }

            const events: IGtmEvent[] = [
                getGenerateLeadEvent(
                    advertisementId,
                    advertiser.id,
                    advertiser.name,
                    formState,
                    EFormStep.STEP_1,
                    method,
                    EPosition.DOWN,
                    advertiser?.agency?.name,
                    advertisementGtmItem.devProjectId,
                    isAdvertiserLoggedIn,
                )
            ];

            if (!isMobile) {
                events.push(getResetEcommerceEvent());
                events.push(
                    getPurchaseEvent(
                        advertisementGtmItem,
                        0,
                        0.2,
                    ));
            }

            gtmSendEvent(...events);

            livemonitorSendEvent({
                p: ['trackEvent', 'Advert', 'Contact-down', 'Telefon'], o:{pn: 'nehnutelnosti'}
            });
            livemonitorSendEvent({
                p: ['trackEvent', 'advert-contact-nehnutelnostisk', advertisementId, 'Telefon'], o:{pn: 'plt-stats'}
            });
        }
    };

    const onVisiblePhoneNumClick = () => {
        const events: IGtmEvent[] = [];
        if (isMobile) {
            events.push(
                getGenerateLeadEvent(
                    advertisementId,
                    advertiser.id,
                    advertiser.name,
                    EFormState.SUCCESS,
                    EFormStep.STEP_2,
                    EMethod.SQL_CLICK_TO_CALL,
                    EPosition.DOWN,
                    advertiser.agency?.name,
                    advertisementGtmItem.devProjectId,
                    isAdvertiserLoggedIn,
                )
            );
            events.push(getResetEcommerceEvent());
            events.push(
                getPurchaseEvent(
                    advertisementGtmItem,
                    0,
                )
            );
        } else {
            events.push(
                getClickPhoneNumberEvent(
                    EPosition.DOWN,
                    advertisementGtmItem.devProjectId,
                )
            );
        }

        gtmSendEvent(...events);
    };

    return (
        <>
            <Text variant="body2" semibold>
                Telefónne číslo:
            </Text>

            <Box
                onClick={onPhoneNumClick}
                sx={{ cursor: !isAfterFetch && 'pointer' }}
            >
                <Stack direction="row" alignItems="baseline" gap={1}>
                    <a
                        href={`tel:${finalNumber}`}
                        style={{textDecoration: 'none'}}
                        onClick={onVisiblePhoneNumClick}
                    >
                        <Text
                            semibold
                            variant="h5"
                            color={(palette: Palette) => palette.primary}
                        >
                            {finalNumber}
                        </Text>
                    </a>

                    {!isAfterFetch && (
                        <Text
                            variant="body3"
                            underLine
                            color={(palette: Palette) => palette.labelPrimary}
                        >
                            Zobraziť číslo
                        </Text>
                    )}
                </Stack>
            </Box>
        </>
    );
};
