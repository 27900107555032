import { useCallback } from 'react';
import useLoading from 'modules/state/app/hook/useLoading';
import { IReportAdvertisement } from 'components/page/advertisement/detail/interfaces/reportAdvertisement/IReportAdvertisement';
import { reportAdvertisement } from 'components/page/advertisement/detail/api/reportAdvertisement/reportAdvertisement';
import { AdvertisementReportDtoTypeEnum } from 'modules/api/generated';
import { AdvertisementReportTypeEnum } from 'components/page/advertisement/detail/enums/reportAdvertisement/AdvertisementReportTypeEnum';

interface IReturn {
    isLoading: boolean;
    onSend: (data: IReportAdvertisement) => Promise<boolean>;
}

const typesMapper: Record<
    AdvertisementReportTypeEnum,
    AdvertisementReportDtoTypeEnum
> = {
    [AdvertisementReportTypeEnum.WRONG]: AdvertisementReportDtoTypeEnum.Wrong,
    [AdvertisementReportTypeEnum.WRONG_CATEGORY]:
        AdvertisementReportDtoTypeEnum.WrongCategory,
    [AdvertisementReportTypeEnum.OUT_OF_DATE]:
        AdvertisementReportDtoTypeEnum.OutOfDate,
    [AdvertisementReportTypeEnum.OTHER]: AdvertisementReportDtoTypeEnum.Other,
};

export const useReportAdvertisement = (id: string): IReturn => {
    const { start: startLoading, stop: stopLoading, isLoading } = useLoading();

    const sendReport = useCallback(
        async (data: IReportAdvertisement): Promise<boolean> => {
            try {
                startLoading();
                const sendResponse = await reportAdvertisement(
                    id,
                    {
                        text: data.text,
                        type: typesMapper[data.type],
                    },
                    data.recaptchaToken
                );
                const { status } = sendResponse;
                const isSuccess = status === 201;

                stopLoading();

                return isSuccess;
            } catch (e) {
                return false;
            } finally {
                stopLoading();
            }
        },
        [startLoading, stopLoading, id]
    );

    return {
        isLoading,
        onSend: sendReport,
    };
};
