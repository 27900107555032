import { FC, ReactNode } from 'react';
import { Box, Theme } from '@mui/material';

interface IWrapper {
    divider?: boolean;
    isAttribute?: boolean;
    isHighlighted?: boolean;
    children: ReactNode | ReactNode[];
}

export const Wrapper: FC<IWrapper> = ({ children, divider, isHighlighted, isAttribute }) => {
    return (
        <Box
            borderBottom={(theme: Theme) =>
                divider && `1px solid ${theme.palette.dividerTransparent}`
            }
        >
            <Box
                px={1}
                pr={2}
                py={0.5}
                borderRadius={2}
                display="inline-block"
                my={{ xs: isAttribute && 1.5 }}
                bgcolor={(theme: Theme) =>
                    isHighlighted
                        ? theme.palette.tintPurpleBg.main
                        : theme.palette.backgroundPrimary.main
                }
            >
                {children}
            </Box>
        </Box>
    );
};
