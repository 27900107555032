import { FC, SVGProps } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { Svg } from 'modules/theme/components/svg/Svg';

interface IBadge {
    text: string;
    onlyIcon?: boolean;
    onClick: () => void;
    icon: FC<SVGProps<SVGSVGElement>>;
}

export const Bagde: FC<IBadge> = ({
    icon,
    text,
    onClick,
    onlyIcon,
}) => {

    return <Box
        gap={0.5}
        display='flex'
        onClick={onClick}
        alignItems='center'
        sx={{cursor: 'pointer'}}
        padding={{ xs: 0.7, md: 2 }}
        maxHeight={{ xs: 26, md: 36 }}
        borderRadius={{ xs: onlyIcon ? '99px' : 1, md: 2 }}
        color={(theme) => theme.palette.labelPrimary.main}
        bgcolor={
            (theme) => theme.palette.backgroundPrimaryElevated.main}
    >
        <Svg component={icon} width={16}/>

        {!onlyIcon &&
            <Text variant='label2' semibold>
                {text}
            </Text>
        }
    </Box>;
};