import React, { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

export const TextAreaLabel: FC = () => {
    return (
        <Text variant={'body2'} semibold>
            Doplňujúce info ku chybe
        </Text>
    );
};
