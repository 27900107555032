import React, { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

export const Title: FC = () => {
    return (
        <Text variant={'h5'} semibold>
            Napíšte predajcovi
        </Text>
    );
};
