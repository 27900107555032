import { FC } from 'react';
import { Box } from '@mui/material';
import { Title } from 'components/page/advertisement/detail/components/common/advertiser/common/phoneNumbersModal/partials/desktop/partials/content/partials/title/Title';
import { PhoneCard } from 'components/page/advertisement/detail/components/common/advertiser/common/phoneNumbersModal/partials/desktop/partials/content/partials/phoneCard/PhoneCard';
import { InfoBox } from 'components/page/advertisement/detail/components/common/advertiser/common/phoneNumbersModal/common/infoBox/InfoBox';

interface IContent {
    phoneNumber: string;
    onPhoneClick?: (phone: string) => void;
}

export const Content: FC<IContent> = ({ phoneNumber, onPhoneClick }) => {
    return (
        <>
            <Box mb={2}>
                <Title />
            </Box>
            <Box mb={2}>
                <PhoneCard phoneNumber={phoneNumber} onClick={onPhoneClick} />
            </Box>
            <InfoBox />
        </>
    );
};
