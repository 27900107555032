import { FC } from 'react';
import { Box } from '@mui/material';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';
import { getPhotoAlt } from 'components/page/advertisement/detail/helpers/photos/GetPhotoAlt';
import { Photo } from 'components/page/advertisement/detail/components/common/photos/partials/sidePhotos/partials/photo/Photo';
import { IPhoto } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/media/partials/photo/IPhoto';

interface ISidePhotos {
    slug: string;
    photos: IPhoto[];
    openGallery: (groupKey?: EGalleryGroupKey, itemIndex?: number) => void;
}

export const SidePhotos: FC<ISidePhotos> = ({ slug, photos, openGallery }) => {
    const photosCount = photos && photos.length;
    const secondPhoto = photosCount > 1 && photos[1];
    const thirdPhoto = photosCount > 2 && photos[2];

    return <Box
        display='flex'
        flexDirection='column'
        height={'100%'}
    >
        {secondPhoto &&
            <Photo
                alt={getPhotoAlt(slug, 2)}
                imageIndex={1}
                image={secondPhoto}
                hasThird={!!thirdPhoto}
                onClick={() => {
                    openGallery(EGalleryGroupKey.PHOTOS);
                }}
            />
        }
        {thirdPhoto &&
            <>
                <Box height={8}/>
                <Photo
                    alt={getPhotoAlt(slug, 3)}
                    imageIndex={2}
                    image={thirdPhoto}
                    onClick={() => {
                        openGallery(EGalleryGroupKey.PHOTOS);
                    }}
                />
            </>
        }
    </Box>;
};
