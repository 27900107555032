import { FC } from 'react';
import { Box } from '@mui/material';
import { Modal as DesktopModal } from 'components/page/advertisement/detail/components/common/advertiser/common/phoneNumbersModal/partials/desktop/Modal';
import { Modal as MobileModal } from 'components/page/advertisement/detail/components/common/advertiser/common/phoneNumbersModal/partials/mobile/Modal';
import { Desktop, Mobile } from 'modules/theme/components/responsive';

interface IPhoneNumbersModal {
    onClose: () => void;
    phoneNumber: string;
    isOpenedFromAgentContactModal?: boolean;
    onPhoneClick?: (phone: string) => void;
}

export const PhoneNumbersModal: FC<IPhoneNumbersModal> = ({
    onClose,
    phoneNumber,
    isOpenedFromAgentContactModal,
    onPhoneClick,
}) => {

    return (
        <Box>
            <Mobile>
                <MobileModal
                    onClose={onClose}
                    phoneNumber={phoneNumber}
                    isOpenedFromAgentContactModal={
                        isOpenedFromAgentContactModal
                    }
                    onPhoneClick={onPhoneClick}
                />
            </Mobile>
            <Desktop>
                <DesktopModal onClose={onClose} phoneNumber={phoneNumber} onPhoneClick={onPhoneClick} />
            </Desktop>
        </Box>
    );
};
