import { AdvertiserContactTypeEnum } from 'components/page/advertisement/detail/enums/advertiser/AdvertiserContactTypeEnum';
import { AdvertisementContactDtoTypeEnum } from 'modules/api/generated';

export const ContactTypeRecord: Record<
    AdvertiserContactTypeEnum,
    AdvertisementContactDtoTypeEnum
> = {
    [AdvertiserContactTypeEnum.FLOOR_PLAN]:
        AdvertisementContactDtoTypeEnum.FloorPlan,
    [AdvertiserContactTypeEnum.INSPECTION]:
        AdvertisementContactDtoTypeEnum.Inspection,
    [AdvertiserContactTypeEnum.MORE_INFO]:
        AdvertisementContactDtoTypeEnum.MoreInfo,
};
