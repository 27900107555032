import { FC } from 'react';
import { CloseButton } from 'components/page/advertisement/common/closeButton/CloseButton';
import { BackButton } from 'components/page/advertisement/detail/components/common/advertiser/common/backButton/BackButton';

interface IHeader {
    onClick: () => void;
    isOpenedFromAgentContactModal?: boolean;
}

export const Header: FC<IHeader> = ({
    onClick,
    isOpenedFromAgentContactModal,
}) => {
    return isOpenedFromAgentContactModal ? (
        <BackButton onClick={onClick} text={'Kontaktné údaje'} />
    ) : (
        <CloseButton onClick={onClick} />
    );
};
