export enum ECategoriesLabel {
    HOUSES = 'Dom',
    COTTAGES_AND_CABINS = 'Chata',
    LANDS = 'Pozemok',
    SPACES = 'Priestor',
    OBJECTS = 'Objekt',

    STUDIO_APARTMENT = 'Garsónka',
    DOUBLE_STUDIO_APARTMENT = 'Dvojgarsónka',
    ONE_ROOM_APARTMENT = '1 izbový byt',
    TWO_ROOM_APARTMENT = '2 izbový byt',
    THREE_ROOM_APARTMENT = '3 izbový byt',
    FOUR_ROOM_APARTMENT = '4 izbový byt',
    FIVE_PLUS_ROOM_APARTMENT = '5 a viac izbový byt',
    HOLIDAY_APARTMENT = 'Apartmán',
    LOFT = 'Loft',
    MAISONETTE = 'Mezonet',
    OTHER_TYPE_OF_APARTMENT = 'Iný byt',
}
