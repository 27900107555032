import { FC } from 'react';
import { Box } from '@mui/material';
import { InfoBox } from 'components/page/advertisement/detail/components/common/advertiser/common/phoneNumbersModal/common/infoBox/InfoBox';
import { Title } from 'components/page/advertisement/detail/components/common/advertiser/common/phoneNumbersModal/partials/mobile/partials/content/partials/title/Title';
import { PhoneCard } from 'components/page/advertisement/detail/components/common/advertiser/common/phoneNumbersModal/partials/mobile/partials/content/partials/phoneCard/PhoneCard';

interface IContent {
    phoneNumber: string;
    onClick?: (phone: string) => void;
}

export const Content: FC<IContent> = ({ phoneNumber, onClick }) => {
    return (
        <>
            <Box mb={2}>
                <Title />
            </Box>
            <Box mb={2}>
                <PhoneCard
                    phoneNumber={phoneNumber}
                    onClick={onClick}
                />
            </Box>
            <InfoBox />
        </>
    );
};
