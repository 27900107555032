import { advertisementDetailApi } from 'modules/api/client';

export const fetchAdvertiserContacts = async (
    advertiserId: string,
    recaptchaToken: string
): Promise<string> => {
    try {
        const response = await advertisementDetailApi.getAdvertiserPhoneNumber(
            advertiserId,
            {
                headers: {
                    'recaptcha-token': recaptchaToken,
                    'recaptcha-type': 'invisible',
                },
            }
        );

        if (response.status !== 200) {
            throw new Error(`Response status code was: ${response.status}`);
        }

        return response.data;
    } catch (error) {
        throw new Error(
            `Failed to fetch advertiser phone number. Error: ${error.message}`
        );
    }
};
