import {Stack, Box} from '@mui/material';
import {Svg} from 'modules/theme/components/svg/Svg';
import React, {FC, FunctionComponent, SVGProps} from 'react';
import {Text} from 'modules/theme/components/text/Text';

interface IContent {
    label: string;
    value: string;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>
}

export const Content: FC<IContent> = ({label, icon, value}) => {
    return <Stack direction="row" gap={1} alignItems="flex-start">
        <Box>
            <Svg icon component={icon} width={16}/>
        </Box>
        <Text variant="body2">{label}:</Text>
        <Text variant="body2">{value}</Text>
    </Stack>;
};
