import { FC } from 'react';
import { Stack, useTheme } from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';
import Icon from 'modules/theme/components/icons/module/DevProjects.svg';
import MappinIcon from 'modules/theme/components/icons/basic/MappinOutline.svg';
import { PromoBanner } from 'components/page/advertisement/detail/components/mainContent/promoBanner/common/PromoBanner';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Desktop, Mobile } from 'modules/theme/components/responsive';

interface IDevProjectsPromoBanner {
    locationCountySlug?: string;
}

export const DevProjectsPromoBanner: FC<IDevProjectsPromoBanner> = ({
    locationCountySlug,
}) => {
    const { palette } = useTheme();
    const ctaText = (
        <>
            <Mobile>
                <Text variant="body2" noWrap underLine>Zobraziť</Text>
            </Mobile>
            <Desktop>
                <Stack direction="row" gap={1}>
                    <Svg icon component={MappinIcon} width={24} />
                    <Text variant="body2" noWrap underLine>Zobraziť na mape</Text>
                </Stack>
            </Desktop>
        </>
    );

    return (
        <PromoBanner
            icon={Icon}
            ctaText={ctaText}
            iconBgColor={palette.identityBasicRed.main}
            text="Všetky novostavby na jednom mieste"
            ctaSubpageHref={
                locationCountySlug
                    ? `developerske-projekty-a-novostavby/${locationCountySlug}`
                    : 'developerske-projekty-a-novostavby'
            }
        />
    );
};
