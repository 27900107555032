import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';
import { EGalleryGroupUrl } from 'components/gallery/enums/group/EGalleryGroupUrl';

export const galleryEGroupKeyToGroupUrlRecord: Record<
    EGalleryGroupKey,
    EGalleryGroupUrl
> = {
    [EGalleryGroupKey.PHOTOS]: EGalleryGroupUrl.PHOTOS,
    [EGalleryGroupKey.VIDEOS]: EGalleryGroupUrl.VIDEOS,
    [EGalleryGroupKey.INSPECTIONS_3D]: EGalleryGroupUrl.INSPECTIONS_3D,
    [EGalleryGroupKey.FLOOR_PLANS]: EGalleryGroupUrl.FLOOR_PLANS,
};
