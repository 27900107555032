import {Box, Stack} from '@mui/material';
import { Svg, SvgIconColor } from 'modules/theme/components/svg/Svg';
import { FC, FunctionComponent, SVGProps } from 'react';
import {Text as CommonText} from 'modules/theme/components/text/Text';


interface IText {
    text: string;
    iconColor?: SvgIconColor;
    iconBgColor: string;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const Text: FC<IText> = ({icon, text, iconColor, iconBgColor}) => {
    return <Stack direction="row" gap={2} alignItems="center">

        <Box bgcolor={iconBgColor} borderRadius="50%" p={1} display="flex">
            <Svg icon component={icon} width={24} color={iconColor ? iconColor : 'labelPrimaryInverse'}/>
        </Box>

        <CommonText variant="body1" mobileVariant="body2" semibold>{text}</CommonText>
    </Stack>;
};
