import { advertisementDetailApi } from 'modules/api/client';
import { AdvertisementReportDto } from 'modules/api/generated';
import { AxiosResponse } from 'axios';

export const reportAdvertisement = async (
    advertisementId: string,
    advertisementReportDto: AdvertisementReportDto,
    recaptchaToken: string
): Promise<AxiosResponse<void>> => {
    try {
        const response = await advertisementDetailApi.reportAdvertisement(
            advertisementId,
            advertisementReportDto,
            {
                headers: {
                    'recaptcha-token': recaptchaToken,
                    'recaptcha-type': 'tickbox',
                },
            }
        );

        if (response.status !== 201) {
            throw new Error(`Response status code was: ${response.status}`);
        }

        return response;
    } catch (error) {
        throw new Error(
            `Failed to report advertisement. Error: ${error.message}`
        );
    }
};
