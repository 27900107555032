import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { FormikProps, Form } from 'formik';
import { Button } from 'modules/theme/components/button/Button';
// import { Recaptcha } from 'components/recaptcha/components/Recaptcha';
import { RadioBoxGroup } from 'modules/theme/components/fields/radioBox/RadioBoxGroup';
import { TextArea } from 'modules/theme/components/fields/textArea/TextArea';
import { IOption } from 'modules/theme/components/fields/radioBox/interfaces/IOption';
import { TextAreaLabel } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/common/textAreaLabel/TextAreaLabel';
import { Message } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/common/message/Message';
import { IReportAdvertisement } from 'components/page/advertisement/detail/interfaces/reportAdvertisement/IReportAdvertisement';
import { RadioGroupLabel } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/common/content/partials/reportForm/common/RadioGroupLabel';
import { TextareaCounter } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/common/content/partials/reportForm/common/TextareaCounter';

interface IReportForm extends FormikProps<IReportAdvertisement> {
    disabled: boolean;
    options: IOption[];
}

export const ReportForm: FC<IReportForm> = ({
    errors,
    values,
    touched,
    disabled,
    handleSubmit,
    setFieldValue,
    options,
}) => {
    return (
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container display="flex" direction="column">
                <Grid item mb={2}>
                    <RadioGroupLabel />
                </Grid>
                <Grid item mb={2}>
                    <RadioBoxGroup
                        options={options}
                        disabled={disabled}
                        value={values.type}
                        onChange={(value) => setFieldValue('type', value)}
                        row={false}
                    />
                </Grid>
                <Grid item mb={2}>
                    <TextAreaLabel />
                </Grid>
                <Grid item mb={2}>
                    <TextArea
                        rows={5}
                        placeholder={'Inzerát má nevhodné foto'}
                        disabled={disabled}
                        value={values.text}
                        errMsg={touched.text && errors.text}
                        onChange={(value) => setFieldValue('text', value)}
                    />
                    <TextareaCounter value={values.text}/>
                </Grid>
                <Grid item mb={2}>
                    <Message />
                </Grid>

                {/* <Grid item mb={2}>
                    <Recaptcha
                        errorMsg={
                            touched.recaptchaToken && errors.recaptchaToken
                                ? errors.recaptchaToken
                                : undefined
                        }
                        onChange={(token) =>
                            setFieldValue('recaptchaToken', token)
                        }
                    />
                </Grid> */}

                <Grid item mb={2} width={150}>
                    <Button
                        type="submit"
                        disabled={disabled}
                        text="Nahlásiť"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Form>
    );
};
