import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';
import {Palette, Theme, Button, Grid} from '@mui/material';

interface IOption {
    name: string;
    value: string;
    isActive: boolean;
    disabled?: boolean;
    uniformWidth: boolean;
    totalOptionsCount: number;
    onClick: (value: string) => void;
}

export const Option: FC<IOption> = ({
    name,
    value,
    onClick,
    disabled,
    isActive,
    uniformWidth,
    totalOptionsCount,
}) => {
    return (
        <Grid item xs={uniformWidth ? 12 / totalOptionsCount : 'auto'}>
            <Button
                fullWidth
                variant="text"
                color="inherit"
                disabled={disabled}
                onClick={() => !isActive && onClick(value)}
                sx={(theme: Theme) => ({
                    height: 32,
                    fontWeight: 600,
                    borderRadius: '99px',
                    textTransform: 'none',
                    textDecoration: 'none',
                    backgroundColor: isActive
                        ? theme.palette.backgroundPrimary.main
                        : theme.palette.backgroundSecondary.main,
                    '&:hover': {
                        backgroundColor: isActive && theme.palette.backgroundPrimary.main,
                    },
                    boxShadow: isActive && '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
                })}
            >
                <Text
                    noWrap
                    semibold
                    variant="body3"
                    color={(palette: Palette) => palette.labelPrimary}
                >
                    {name}
                </Text>
            </Button>
        </Grid>
    );
};
