import { FC } from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';
import { Text } from 'modules/theme/components/text/Text';
import { Svg } from 'modules/theme/components/svg/Svg';

interface IGalleryAppBar {
    title: string;
    onClose: () => void;
}

export const GalleryAppBar: FC<IGalleryAppBar> = ({title, onClose}) => {

    return <AppBar
        position='sticky'
        variant='header'
        sx={(theme) => ({
            zIndex: 2000,
            bgcolor: theme.palette.backgroundPrimary.main,
            color: theme.palette.labelPrimary.main,
            borderBottom: { md: `1px solid ${theme.palette.dividerTransparent}` }
        })}
    >
        <Toolbar sx={{
            display: 'flex',
            position: 'relative',
            alignItems: 'center'
        }}>
            <Svg
                icon
                width={24}
                component={CloseIcon}
                onIconClick={onClose}
                sx={{
                    cursor: 'pointer'
                }}
            />

            <Box
                display='flex'
                width='100%'
                justifyContent='center'
                textOverflow='ellipsis'
                overflow='hidden'
                whiteSpace='nowrap'
                paddingX={1}
            >
                <Text variant='body2' semibold noWrap>{title}</Text>
            </Box>
        </Toolbar>
    </AppBar>;
};