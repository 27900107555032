import { FC } from 'react';
import { BackButton } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/mobile/partials/header/partials/backButton/BackButton';
import { CloseButton } from 'components/page/advertisement/common/closeButton/CloseButton';

interface IHeader {
    onClick: () => void;
    onBackClick: () => void;
    isLastStep: boolean;
}

export const Header: FC<IHeader> = ({ onClick, onBackClick, isLastStep }) => {
    return isLastStep ? (
        <CloseButton onClick={onClick} />
    ) : (
        <BackButton onBackClick={onBackClick} />
    );
};
