import { useState, useEffect } from 'react';

interface IReturn {
    screenHeight: number;
}

export const useScreenHeight = (): IReturn => {
    const hasWindow = typeof window !== 'undefined';
    const innerHeight = hasWindow ? window.innerHeight : null;

    const [screenHeight, setScreenHeight] = useState(innerHeight);

    useEffect(() => {
        if (hasWindow) {
            const handleResize = (): void => {
                setScreenHeight(window.innerHeight);
            };

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow]);

    return {
        screenHeight,
    };
};