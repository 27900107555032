import { IAdvertiser } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/advertiser/IAdvertiser';
import { IGtmAdvertiser } from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/partials/advertiser/IGtmAdvertiser';
import { mapIAgencyToIGtmAgency } from 'modules/api/clients/advertisement/detail/helpers/mappers/advertisementDetail/advertiser/agency/mapIAgencyToIGtmAgency';
import { advertiserTypeEnumsRecord } from 'modules/api/clients/advertisement/common/constants/records/advertiser/advertiserTypeEnumsRecord';

export const mapIAdvertiserToIGtmAdvertiser = (
    advertiser: IAdvertiser
): IGtmAdvertiser => ({
    id: advertiser.id,
    name: advertiser.name,
    isPro: advertiser.isPro,
    type: advertiserTypeEnumsRecord[advertiser.type],
    ...(!!advertiser.agency && {
        agency: mapIAgencyToIGtmAgency(advertiser.agency),
    }),
    slugName: advertiser.slugName,
});
