import { FC } from 'react';
import useDialog from 'modules/dialog/useDialog';
import { Theme, useMediaQuery } from '@mui/material';
import { ReportButton } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/reportButton/ReportButton';
import { Modal } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/Modal';

interface IReportAdvertisement {
    id: string;
    btnText: string;
}

export const ReportAdvertisement: FC<IReportAdvertisement> = ({
    id,
    btnText,
}) => {

    // TODO DODO fullScreen needed or refactor
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const {
        component: modal,
        show: showDialog,
        hide: hideDialog,
    } = useDialog({
        content: <Modal onClose={() => hideDialog()} id={id} />,
        paperProps: {
            sx: {
                width: 560,
                borderRadius: (theme) => ({
                    xs: theme.spacing(2, 2, 0, 0),
                    md: theme.spacing(2),
                })
            },
        },
        dialogContentProps: {
            sx: {
                padding: { xs: 3, md: 4 },
            },
        },
        wrapContentIntoBox: false,
        fullScreen: isMobile,
        fullWidth: isMobile,
        heightFitContent: isMobile,
        positionBottom: isMobile,
    });

    return (
        <>
            <ReportButton onClick={() => showDialog()} text={btnText} />
            {modal}
        </>
    );
};
