import { FC, useEffect } from 'react';
import { Text } from 'modules/theme/components/text/Text';

interface IFullText {
    text: string;
}

export const FullText: FC<IFullText> = ({ text }) => {
    // TODO useRef()
    const textId = 'full-text-description';

    useEffect(() => {
        if (document) {
            const textElement = document.getElementById(textId);
            if (textElement) {
                textElement.innerHTML = text;
            }
        }
    }, [text]);

    return (
        <Text id={textId} variant={'body2'} preWrap>
            {text}
        </Text>
    );
};
