import { FC } from 'react';
import { useTheme, Box } from '@mui/material';
import { Img } from 'components/page/advertisement/detail/components/img/Img';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';
import { EImgWidth } from 'components/page/advertisement/detail/components/img/enums/EImgWidth';
import { IPhoto } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/media/partials/photo/IPhoto';

interface IDesktopImage {
    alt?: string;
    image: IPhoto;
    isBordered: boolean;
    openGallery: (groupKey?: EGalleryGroupKey, itemIndex?: number) => void;
    isPlentyOfPhotos?: boolean;
}

export const DesktopImage: FC<IDesktopImage> = ({
    alt,
    image,
    isBordered,
    openGallery,
    isPlentyOfPhotos,
}) => {
    const theme = useTheme();

    const imageBottomOffset = 290;

    return (
        <Box overflow={'visible'}>
            <Box
                position={'relative'}
                maxHeight={500}
                minHeight={350}
                height={{
                    xs: `calc(100vh - ${imageBottomOffset}px)`,
                    lg: isPlentyOfPhotos ? `calc(100vh - ${imageBottomOffset}px)` : '100vh',
                }}
            >
                <Img
                    alt={alt || `advertisement-photo-${1}`}
                    width={775}
                    height={408}
                    srcSet={{
                        [EImgWidth.medium]: image.mediumUrl,
                        [EImgWidth.large]: image.largeUrl || image.origUrl,
                    }}
                    onClick={() => {
                        openGallery(EGalleryGroupKey.PHOTOS, 0);
                    }}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderTopLeftRadius: theme.spacing(2),
                        borderBottomLeftRadius: theme.spacing(2),
                        borderTopRightRadius: isBordered && theme.spacing(2),
                        borderBottomRightRadius: isBordered && theme.spacing(2),
                    }}
                />
            </Box>
        </Box>
    );
};