import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';

interface IMainPrice {
    price: string;
    arePowerCostsIncluded?: boolean;
}

export const MainPrice: FC<IMainPrice> = ({price, arePowerCostsIncluded}) => {
    return <Text variant="h3" semibold>
        {price} {arePowerCostsIncluded && 's energiami'}
    </Text>;
};
