import { FC } from 'react';
import { Box } from '@mui/material';
import {
    Badges
} from 'components/page/advertisement/detail/components/common/photos/partials/photoOverlay/partials/bottomContent/partials/badges/Badges';
import {
    DevProject
} from 'components/page/advertisement/detail/components/common/photos/partials/photoOverlay/partials/bottomContent/partials/devProject/DevProject';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';

interface IBottomContent {
    areVideos?: boolean;
    photosCount: number;
    isDevProject: boolean;
    areFloorPlans?: boolean;
    isInspection3d?: boolean;
    openGallery: (groupKey?: EGalleryGroupKey, itemIndex?: number) => void;
    advertisementId: string;
}

export const BottomContent: FC<IBottomContent> = ({
    areVideos,
    photosCount,
    openGallery,
    isDevProject,
    areFloorPlans,
    isInspection3d,
    advertisementId,
}) => {

    return <>
        <Box
            left={24}
            bottom={24}
            position='absolute'
        >
            <Badges
                areVideos={areVideos}
                photosCount={photosCount}
                openGallery={openGallery}
                areFloorPlans={areFloorPlans}
                isInspection3d={isInspection3d}
                advertisementId={advertisementId}
            />
        </Box>

        {isDevProject &&
            <Box
                right={24}
                bottom={24}
                position='absolute'
            >
                <DevProject/>
            </Box>
        }
    </>;
};
