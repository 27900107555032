import {FC, ReactNode} from 'react';
import Link from 'components/link/Link';
import {AppConfigService} from 'modules/appConfig/AppConfigService';

interface ICta {
    subpage: string;
    text: ReactNode;
    onClick?: () => void;
}

export const Cta: FC<ICta> = ({subpage, text, onClick}) => {
    return <Link href={`${AppConfigService.getNehnutelnostiUrl()}/${subpage}`} onClick={onClick}>
        {text}
    </Link>;
};
