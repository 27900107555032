import React, { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { MAX_CHARACTERS_REPORT_MESSAGE } from 'components/page/advertisement/detail/constants/form';

interface ITextareaCounter {
    value: string;
}

export const TextareaCounter: FC<ITextareaCounter> = ({value}) => {
    const currentCharactersLength = value.length;
    const isMoreThanMaxCharacters = currentCharactersLength > MAX_CHARACTERS_REPORT_MESSAGE;

    return (
        <Text variant={'label1'} color={palette =>
            isMoreThanMaxCharacters ? palette.error : palette.labelSecondary} semibold>
            Maximálny počet znakov {currentCharactersLength}/{MAX_CHARACTERS_REPORT_MESSAGE}
        </Text>
    );
};
