import { FC } from 'react';
import { Box } from '@mui/material';
import { Header } from 'components/page/advertisement/detail/components/common/advertiser/common/phoneNumbersModal/partials/desktop/partials/header/Header';
import { Content } from 'components/page/advertisement/detail/components/common/advertiser/common/phoneNumbersModal/partials/desktop/partials/content/Content';

interface IModal {
    onClose: () => void;
    phoneNumber: string;
    onPhoneClick?: (phone: string) => void;
}

export const Modal: FC<IModal> = ({ onClose, phoneNumber, onPhoneClick }) => {
    return (
        <>
            <Box mb={2}>
                <Header onClick={onClose} />
            </Box>
            <Box>
                <Content phoneNumber={phoneNumber} onPhoneClick={onPhoneClick} />
            </Box>
        </>
    );
};
