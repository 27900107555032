import {FC} from 'react';
import {Theme, Box, Grid} from '@mui/material';

interface IWrapper {
    children?: JSX.Element;
}

export const Wrapper: FC<IWrapper> = ({children}) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
        >
            <Grid
                container
                height={40}
                spacing={0.5}
                paddingRight={0.5}
                paddingBottom={0.5}
                borderRadius="99px"
                bgcolor={(theme: Theme) => theme.palette.backgroundSecondary.main}
            >
                {children}
            </Grid>
        </Box>
    );
};
