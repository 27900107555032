import {ESubCategory} from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import {ECategoriesLabel} from 'components/page/advertisement/detail/enums/heading/category/ECategoriesLabel';

export const SubCategoriesToMainEnumsRecord: Record<ESubCategory, ECategoriesLabel> = {
    [ESubCategory.FAMILY_HOUSE]: ECategoriesLabel.HOUSES,
    [ESubCategory.COUNTRY_HOUSE]: ECategoriesLabel.HOUSES,
    [ESubCategory.MOBILE_HOUSE]: ECategoriesLabel.HOUSES,
    [ESubCategory.HOUSEBOAT]: ECategoriesLabel.HOUSES,
    [ESubCategory.OTHER_OBJECT_FOR_HOUSING]: ECategoriesLabel.HOUSES,

    [ESubCategory.CABIN_AND_LOG_CABIN]: ECategoriesLabel.COTTAGES_AND_CABINS,
    [ESubCategory.COTTAGE_AND_RECREATION_HOUSE]: ECategoriesLabel.COTTAGES_AND_CABINS,
    [ESubCategory.GARDEN_HUT]: ECategoriesLabel.COTTAGES_AND_CABINS,
    [ESubCategory.OTHER_RECREATIONAL_OBJECT]: ECategoriesLabel.COTTAGES_AND_CABINS,

    [ESubCategory.LAND_FOR_FAMILY_HOUSE]: ECategoriesLabel.LANDS,
    [ESubCategory.RECREATIONAL_LAND]: ECategoriesLabel.LANDS,
    [ESubCategory.LAND_FOR_HOUSING_CONSTRUCTION]: ECategoriesLabel.LANDS,
    [ESubCategory.LAND_FOR_CIVIC_AMENITIES]: ECategoriesLabel.LANDS,
    [ESubCategory.COMMERCIAL_ZONE]: ECategoriesLabel.LANDS,
    [ESubCategory.INDUSTRIAL_ZONE]: ECategoriesLabel.LANDS,
    [ESubCategory.MIXED_ZONE]: ECategoriesLabel.LANDS,
    [ESubCategory.GARDEN]: ECategoriesLabel.LANDS,
    [ESubCategory.ORCHARD]: ECategoriesLabel.LANDS,
    [ESubCategory.VINEYARD_AND_HOP_GARDEN]: ECategoriesLabel.LANDS,
    [ESubCategory.MEADOW_AND_PASTURE]: ECategoriesLabel.LANDS,
    [ESubCategory.ARABLE_LAND]: ECategoriesLabel.LANDS,
    [ESubCategory.FOREST_LAND]: ECategoriesLabel.LANDS,
    [ESubCategory.POND_AND_LAKE]: ECategoriesLabel.LANDS,
    [ESubCategory.LAND_FOR_ADVERTISING]: ECategoriesLabel.LANDS,
    [ESubCategory.OTHER_TYPE_OF_LAND]: ECategoriesLabel.LANDS,
    [ESubCategory.OTHER_LAND_TO_BUILDING]: ECategoriesLabel.LANDS,
    [ESubCategory.OTHER_AGRICULTURAL_LAND]: ECategoriesLabel.LANDS,
    [ESubCategory.FARM_SETTLEMENT]: ECategoriesLabel.LANDS,
    [ESubCategory.SECONDARY_LAND_FOR_FAMILY_HOUSES]: ECategoriesLabel.LANDS,
    [ESubCategory.SECONDARY_RECREATIONAL_LAND]: ECategoriesLabel.LANDS,
    [ESubCategory.SECONDARY_COMMERCIAL_PLOTS]: ECategoriesLabel.LANDS,
    [ESubCategory.SECONDARY_GARDEN]: ECategoriesLabel.LANDS,
    [ESubCategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS]: ECategoriesLabel.LANDS,
    [ESubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND]: ECategoriesLabel.LANDS,

    [ESubCategory.OFFICES]: ECategoriesLabel.SPACES,
    [ESubCategory.BUSINESS_SPACES]: ECategoriesLabel.SPACES,
    [ESubCategory.RESTAURANT_SPACES]: ECategoriesLabel.SPACES,
    [ESubCategory.STORAGE_AREAS]: ECategoriesLabel.SPACES,
    [ESubCategory.SPACE_FOR_PRODUCTION]: ECategoriesLabel.SPACES,
    [ESubCategory.REPAIR_AREA]: ECategoriesLabel.SPACES,
    [ESubCategory.SPORTS_FACILITIES]: ECategoriesLabel.SPACES,
    [ESubCategory.SPACE_FOR_ADVERTISEMENT]: ECategoriesLabel.SPACES,
    [ESubCategory.OTHER_TYPE_OF_SPACE]: ECategoriesLabel.SPACES,
    [ESubCategory.SECONDARY_PRODUCTION]: ECategoriesLabel.SPACES,
    [ESubCategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT]: ECategoriesLabel.SPACES,

    [ESubCategory.RESTAURANT]: ECategoriesLabel.OBJECTS,
    [ESubCategory.DETACHED_GARAGE]: ECategoriesLabel.OBJECTS,
    [ESubCategory.HISTORICAL_OBJECT]: ECategoriesLabel.OBJECTS,
    [ESubCategory.SPORT_OBJECT]: ECategoriesLabel.OBJECTS,
    [ESubCategory.MANUFACTURING_FACILITY]: ECategoriesLabel.OBJECTS,
    [ESubCategory.HOTEL_AND_PENSION]: ECategoriesLabel.OBJECTS,
    [ESubCategory.COMMERCIAL_OBJECT]: ECategoriesLabel.OBJECTS,
    [ESubCategory.WAREHOUSE]: ECategoriesLabel.OBJECTS,
    [ESubCategory.POLYFUNCTIONAL_BUILDING]: ECategoriesLabel.OBJECTS,
    [ESubCategory.OFFICE_BUILDING]: ECategoriesLabel.OBJECTS,
    [ESubCategory.OBJECT_FOR_BREEDING_ANIMALS]: ECategoriesLabel.OBJECTS,
    [ESubCategory.RENTAL_HOUSE]: ECategoriesLabel.OBJECTS,
    [ESubCategory.COMMERCIAL_FACILITY]: ECategoriesLabel.OBJECTS,
    [ESubCategory.AGRICULTURAL_OBJECT]: ECategoriesLabel.OBJECTS,
    [ESubCategory.APARTMENT_HOUSE]: ECategoriesLabel.OBJECTS,
    [ESubCategory.CONSOLIDATED_GROUND]: ECategoriesLabel.OBJECTS,
    [ESubCategory.REPAIR_FACILITY]: ECategoriesLabel.OBJECTS,
    [ESubCategory.SPA]: ECategoriesLabel.OBJECTS,
    [ESubCategory.GAS_STATION]: ECategoriesLabel.OBJECTS,
    [ESubCategory.SMALL_POWER_STATION]: ECategoriesLabel.OBJECTS,
    [ESubCategory.MOBILE_CELLS_AND_STANDS]: ECategoriesLabel.OBJECTS,
    [ESubCategory.OTHER_TYPE_OF_OBJECT]: ECategoriesLabel.OBJECTS,
    [ESubCategory.SECONDARY_GARAGE_AND_PARKING]: ECategoriesLabel.OBJECTS,
    [ESubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION]: ECategoriesLabel.OBJECTS,
    [ESubCategory.SECONDARY_THE_SHOP]: ECategoriesLabel.OBJECTS,
    [ESubCategory.SECONDARY_A_RESTAURANT]: ECategoriesLabel.OBJECTS,
    [ESubCategory.SECONDARY_WAREHOUSES]: ECategoriesLabel.OBJECTS,
    [ESubCategory.SECONDARY_HOTEL_GUESTHOUSE]: ECategoriesLabel.OBJECTS,

    [ESubCategory.STUDIO_APARTMENT]: ECategoriesLabel.STUDIO_APARTMENT,
    [ESubCategory.DOUBLE_STUDIO_APARTMENT]: ECategoriesLabel.DOUBLE_STUDIO_APARTMENT,
    [ESubCategory.ONE_ROOM_APARTMENT]: ECategoriesLabel.ONE_ROOM_APARTMENT,
    [ESubCategory.TWO_ROOM_APARTMENT]: ECategoriesLabel.TWO_ROOM_APARTMENT,
    [ESubCategory.THREE_ROOM_APARTMENT]: ECategoriesLabel.THREE_ROOM_APARTMENT,
    [ESubCategory.FOUR_ROOM_APARTMENT]: ECategoriesLabel.FOUR_ROOM_APARTMENT,
    [ESubCategory.FIVE_PLUS_ROOM_APARTMENT]: ECategoriesLabel.FIVE_PLUS_ROOM_APARTMENT,
    [ESubCategory.HOLIDAY_APARTMENT]: ECategoriesLabel.HOLIDAY_APARTMENT,
    [ESubCategory.LOFT]: ECategoriesLabel.LOFT,
    [ESubCategory.MAISONETTE]: ECategoriesLabel.MAISONETTE,
    [ESubCategory.OTHER_TYPE_OF_APARTMENT]: ECategoriesLabel.OTHER_TYPE_OF_APARTMENT,
    [ESubCategory.SECONDARY_STUDIO_APARTMENT]: ECategoriesLabel.STUDIO_APARTMENT,
    [ESubCategory.SECONDARY_THREE_ROOM_APARTMENT]: ECategoriesLabel.THREE_ROOM_APARTMENT,
};
