import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';

interface ITitle {
    title: string
}

export const Title: FC<ITitle> = ({title}) => {
    return <Text
        component={'h3'}
        variant="h6"
        semibold
        noWrap
    >
        {title}
    </Text>;
};
