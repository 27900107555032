import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';


interface ITitle {
    title: string
}

export const Title: FC<ITitle> = ({title}) => {
    return <Text
        component="h2"
        variant="h4"
        semibold
        noWrap
    >
        {title}
    </Text>;
};
