import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';
import {Button as MuiButton, Palette} from '@mui/material';
import clipboardCopy from 'clipboard-copy';
import useNotification from 'modules/notification/useNotification';

interface IProps {
    phoneNumber: string;
    onClick?: () => void;
}

export const CopyToClipboardButton: FC<IProps> = ({
    phoneNumber,
    onClick,
}) => {
    const { success: showSuccessFlashMessage } = useNotification();

    const onButtonClick = async () => {
        await clipboardCopy(phoneNumber);
        showSuccessFlashMessage('Číslo bolo skopírované do schránky');
        if (typeof onClick === 'function') {
            onClick();
        }
    };

    return <>
        <Text
            semibold
            variant="h5"
            color={(palette: Palette) => palette.primary}
        >
            {phoneNumber}
        </Text>
        <MuiButton
            size={'small'}
            onClick={onButtonClick}
            variant={'contained'}
            color={'success'}
            sx={{
                height: '32px',
                boxShadow: 'none!important',
                textTransform: 'none',
                borderRadius: '20px',
                color: '#2D2926',
                background: 'rgba(45, 41, 38, 0.04)',
                '&:hover': {
                    backgroundColor: 'rgba(45, 41, 38, 0.04)',
                },
            }}
        >
            Kopírovať číslo
        </MuiButton>
    </>;
};
