import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';

interface IUnitPrice {
    price: string;
}

export const UnitPrice: FC<IUnitPrice> = ({price}) => {
    return <Text variant="label2" color={(palette) => palette.labelSecondary}>
        {price}
    </Text>;
};
