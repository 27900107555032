import { useDebouncedCallback } from 'use-debounce';
import { Gallery } from 'components/gallery/components/Gallery';
import { IGroup } from 'components/gallery/interfaces/group/IGroup';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';
import { useCallback, useState, useMemo, ReactNode } from 'react';

interface IParameters {
    isOpen: boolean;
    title: string;
    groups: IGroup[];
    advertisementId?: string;
    advertisementSlug?: string;
    activeGroupKey: EGalleryGroupKey;
    gallerySideContent?: ReactNode;
    onOpenRedirectCallback?: (groupKey: EGalleryGroupKey) => void;
    onCloseRedirectCallback?: () => void;
    onChangeRedirectCallback?: (groupKey: EGalleryGroupKey) => void;
}

interface IReturn {
    gallery: React.ReactElement;
    open: (groupKey?: EGalleryGroupKey, itemIndex?: number) => void;
}

export const useGallery = (parameters: IParameters): IReturn => {
    const [ isGalleryOpen, setIsGalleryOpen ] = useState(parameters.isOpen);
    const [ activeGroupKey, setActiveGroupKey ] = useState<EGalleryGroupKey>(
        parameters.isOpen && parameters.activeGroupKey,
    );
    const [ activeItemIndex, setActiveItemIndex ] = useState<number>(undefined);
    const isFullscreenGalleryOpen = useMemo(() => {
        return activeItemIndex !== undefined;
    }, [activeItemIndex]);

    const onOpenRedirect = useDebouncedCallback(parameters.onOpenRedirectCallback, 300);
    const onCloseRedirect = useDebouncedCallback(parameters.onCloseRedirectCallback, 300);
    const onChangeRedirect = useDebouncedCallback(parameters.onChangeRedirectCallback, 300);

    const open = useCallback((groupKey?: EGalleryGroupKey, itemIndex?: number) => {
        setActiveGroupKey(groupKey || EGalleryGroupKey.PHOTOS);
        setActiveItemIndex(itemIndex || undefined);
        setIsGalleryOpen(true);
        onOpenRedirect(groupKey || EGalleryGroupKey.PHOTOS);

    }, [onOpenRedirect]);

    const change = useCallback((groupKey?: EGalleryGroupKey) => {
        setActiveGroupKey(groupKey);
        onChangeRedirect(groupKey);
    }, [onChangeRedirect]);

    const gallery = <Gallery
        isOpen={isGalleryOpen}
        title={parameters.title}
        groups={parameters.groups}
        onFullscreenGalleryClose={() => {
            setActiveItemIndex(undefined);
        }}
        onClose={() => {
            setActiveGroupKey(undefined);
            setActiveItemIndex(undefined);
            setIsGalleryOpen(false);

            onCloseRedirect();
        }}
        activeGroupKey={activeGroupKey}
        activeItemIndex={activeItemIndex}
        onActiveGroupKeyChanged={change}
        onActiveItemIndexChanged={setActiveItemIndex}
        isFullscreenGalleryOpen={isFullscreenGalleryOpen}
        gallerySideContent={parameters.gallerySideContent}
    />;

    return {
        open,
        gallery,
    };
};
