import {FC} from 'react';
import {Stack} from '@mui/material';
import {
    UnitPrice
} from 'components/page/advertisement/detail/components/mainContent/heading/partials/price/partials/unit/UnitPrice';
import {
    MainPrice
} from 'components/page/advertisement/detail/components/mainContent/heading/partials/price/partials/main/MainPrice';
import {
    PowerCosts
} from 'components/page/advertisement/detail/components/mainContent/heading/partials/price/partials/powerCosts/PowerCosts';
import {
    NoteToPrice
} from 'components/page/advertisement/detail/components/mainContent/heading/partials/price/partials/noteToPrice/NoteToPrice';

interface IPrice {
    price: string;
    unitPrice?: string;
    noteToPrice?: string;
    powerCostsValue?: string;
    arePowerCostsIncluded?: boolean;
}

export const Price: FC<IPrice> = ({
    price,
    unitPrice,
    noteToPrice,
    powerCostsValue,
    arePowerCostsIncluded,
}) => {
    return <Stack direction="column" gap={.5}>
        <MainPrice
            price={price}
            arePowerCostsIncluded={arePowerCostsIncluded}
        />
        {powerCostsValue && <PowerCosts value={powerCostsValue}/>}
        {unitPrice && <UnitPrice price={unitPrice}/>}
        {noteToPrice && <NoteToPrice note={noteToPrice}/>}
    </Stack>;
};
