import { useMemo } from 'react';
import { Box } from '@mui/material';
import {
    GalleryElement
} from 'components/gallery/components/partials/dialog/partials/items/partials/element/Element';
import { IItem } from 'components/gallery/interfaces/group/partials/item/IItem';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';
import { IGroup } from 'components/gallery/interfaces/group/IGroup';
import { ReactImageGalleryItem } from 'react-image-gallery';

interface IReturn {
    activeItems: IItem[];
    activeGalleryItems: ReactImageGalleryItem[];
}

export const useGalleryItems = (
    isFullscreenGalleryOpen: boolean,
    activeGroupKey: EGalleryGroupKey,
    groups: IGroup[],
): IReturn => {
    const activeItems = useMemo(() => {
        return groups.find((group) => group.key === activeGroupKey)?.items || [];
    }, [activeGroupKey, groups]);

    const activeGalleryItems: ReactImageGalleryItem[] = useMemo(() => {
        return activeItems.map(item => ({
            original: '',
            thumbnail: item.thumbmail,
            meta: { element: item.element },
            renderItem: (item: ReactImageGalleryItem) => {
                return <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    height='calc(100vh - 120px)'
                >
                    <GalleryElement
                        element={item['meta'].element}
                        isFullscreen={isFullscreenGalleryOpen}
                    />
                </Box>;
            },
        }));
    }, [activeItems, isFullscreenGalleryOpen]);

    return {
        activeItems,
        activeGalleryItems
    };
};
