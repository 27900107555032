import { FC, useState } from 'react';
import { FullText } from 'components/page/advertisement/detail/components/mainContent/description/partials/textBox/partials/fullText/FullText';
import { HiddenText } from 'components/page/advertisement/detail/components/mainContent/description/partials/textBox/partials/hiddenText/HiddenText';
import { ShowOrHideButton } from 'components/page/advertisement/detail/components/mainContent/description/partials/textBox/partials/showOrHideButton/ShowOrHideButton';

interface ITextBox {
    text: string;
}

export const TextBox: FC<ITextBox> = ({ text }) => {
    const hasEnoughText = text.length > 480;
    const [isShowMore, setIsShowMore] = useState<boolean>(false);

    return (
        <>
            {hasEnoughText && !isShowMore ? <HiddenText text={text} /> : <FullText text={text} />}
            {(hasEnoughText) && (
                <ShowOrHideButton
                    isShowMore={isShowMore}
                    onBtnClick={() => setIsShowMore(!isShowMore)}
                />
            )}
        </>
    );
};
