import { FC } from 'react';
import { Box } from '@mui/material';
import { Header } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/desktop/partials/header/Header';
import { Content } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/common/content/Content';

interface IDesktopModal {
    onClose: () => void;
    id: string;
}

const DesktopModal: FC<IDesktopModal> = ({ onClose, id }) => {
    return (
        <>
            <Box mb={2}>
                <Header onClick={onClose} />
            </Box>
            <Box>
                <Content id={id} onClose={onClose} />
            </Box>
        </>
    );
};

export default DesktopModal;
