import { FC } from 'react';
import { Stack } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
import MappinIcon from 'modules/theme/components/icons/basic/Mappin.svg';

interface ILocation {
    location?: string;
}

export const Location: FC<ILocation> = ({ location }) => {
    return (
        <Stack gap={0.5} direction="row" alignItems="center">
            <Svg icon component={MappinIcon} width={16} />
            <Text variant="body2" noWrap>
                {location}
            </Text>
        </Stack>
    );
};
