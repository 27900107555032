import {FC, useEffect} from 'react';
import {Theme, useMediaQuery} from '@mui/material';
import {Button} from 'modules/theme/components/button/Button';
import useDialog from 'modules/dialog/useDialog';
import {
    PhoneNumbersModal
} from 'components/page/advertisement/detail/components/common/advertiser/common/phoneNumbersModal/PhoneNumbersModal';
//import useHiddenRecaptcha from 'components/recaptcha/hooks/useHiddenRecaptcha';
import {useAdvertiserPhoneNumber} from 'components/page/advertisement/detail/hooks/advertiser/useAdvertiserPhoneNumber';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';

interface IPhoneNumberButton {
    btnText: string;
    advertiserId: string;
    advertisementId: string;
    isOpenedFromAgentContactModal?: boolean;
    onShowNumber?: () => void;
    onPhoneClick?: (phone: string) => void;
    lmEventPltParams?: string[];
    lmEventNskParams?: string[];
}

export const PhoneNumberButton: FC<IPhoneNumberButton> = ({
    btnText,
    advertiserId,
    advertisementId,
    isOpenedFromAgentContactModal,
    onShowNumber,
    onPhoneClick,
    lmEventNskParams,
    lmEventPltParams,
}) => {
    const { isLoading, phoneNumber, fetchPhoneNumber } =
        useAdvertiserPhoneNumber(advertiserId);
    // const { component: invisibleRecaptcha, submitRecaptcha } =
    //     useHiddenRecaptcha(fetchPhoneNumber);
    const {
        livemonitor: { sendEvent: livemonitorSendEvent },
    } = useAnalytics();

    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const {
        component: modal,
        show: showDialog,
        hide: hideDialog,
    } = useDialog({
        content: (
            <PhoneNumbersModal
                onClose={() => hideDialog()}
                phoneNumber={phoneNumber}
                isOpenedFromAgentContactModal={isOpenedFromAgentContactModal}
                onPhoneClick={onPhoneClick}
            />
        ),
        paperProps: {
            sx: {
                borderRadius: (theme) => ({
                    xs: theme.spacing(2, 2, 0, 0),
                    md: theme.spacing(2),
                })
            },
        },
        dialogContentProps: {
            sx: {
                padding: { xs: 3, md: 4 },
            },
        },
        wrapContentIntoBox: false,
        fullScreen: isMobile,
        fullWidth: isMobile,
        heightFitContent: isMobile,
        positionBottom: isMobile,
    });

    useEffect(() => {
        if (phoneNumber) {
            if (typeof onShowNumber === 'function') {
                onShowNumber();
            }
            livemonitorSendEvent({
                p: lmEventNskParams, o:{pn: 'nehnutelnosti'}
            });
            livemonitorSendEvent({
                p: lmEventPltParams, o:{pn: 'plt-stats'}
            });
        }

        if (phoneNumber && !isMobile) {
            showDialog();
        }
    }, [showDialog, phoneNumber, onShowNumber, livemonitorSendEvent, advertisementId, isMobile, lmEventNskParams, lmEventPltParams]);

    useEffect(() => {
        if (phoneNumber && isMobile) {
            const link: HTMLElementTagNameMap['a'] = document.createElement('a');
            link.href = `tel:${phoneNumber}`;
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        }
    }, [phoneNumber, isMobile]);

    return (
        <>
            <Button
                fullWidth
                text={btnText}
                disabled={isLoading}
                onClick={() => {
                    if (isMobile) {
                        return fetchPhoneNumber('token');
                    }

                    if (phoneNumber) {
                        showDialog();
                    } else {
                        fetchPhoneNumber('token');
                        //submitRecaptcha();
                    }
                }}
            />
            {/*{invisibleRecaptcha}*/}
            {modal}
        </>
    );
};
