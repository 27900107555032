import { FC } from 'react';
import { Box } from '@mui/material';
import { DevProjectParameters } from 'components/page/advertisement/detail/components/mainContent/partOfDevProject/partials/devProjectParametes/DevProjectParameters';
import { Title } from 'components/page/advertisement/detail/components/mainContent/partOfDevProject/partials/title/Title';
import { ShowProjectButton } from 'components/page/advertisement/detail/components/mainContent/partOfDevProject/partials/showProjectButton/ShowProjectButton';
import { IDevProject } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/devProject/IDevProject';

interface IPartOfDevProject {
    devProject: IDevProject;
}

export const PartOfDevProject: FC<IPartOfDevProject> = ({ devProject }) => {
    return (
        <>
            <Box mb={2}>
                <Title />
            </Box>
            <Box mb={2}>
                <DevProjectParameters
                    state={devProject.state}
                    yearOfConstruction={devProject?.yearOfConstruction}
                    numberOfActiveAdvertisements={
                        devProject.numberOfActiveAdvertisements
                    }
                />
            </Box>
            <ShowProjectButton
                projectId={devProject.relationId}
                slug={devProject.slugName}
            />
        </>
    );
};
