import {FC} from 'react';
import {Button} from 'modules/theme/components/button/Button';
import Link from 'components/link/Link';

interface IPhoneNumberButton {
    btnText: string;
    advertisementId: string;
    onPhoneClick?: () => void;
    phoneNumber?: string;
    lmEventPltParams?: string[];
    lmEventNskParams?: string[];
}

export const MobilePhoneNumberButton: FC<IPhoneNumberButton> = ({
    btnText,
    phoneNumber,
    onPhoneClick,
}) => {

    if (!phoneNumber) {
        return <Button
            fullWidth
            text={btnText}
            disabled
        />;
    }

    return (
        <Link href={`tel:${phoneNumber}`} onClick={() => {
            if (typeof onPhoneClick === 'function') {
                onPhoneClick();
            }
        }}>
            <Button
                fullWidth
                text={btnText}
            />
        </Link>
    );
};
