import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import ArrowBackIcon from 'modules/theme/components/icons/basic/ArrowBack.svg';
import { Title } from 'components/page/advertisement/detail/components/common/advertiser/common/backButton/partials/title/Title';

interface IBackButton {
    onClick: () => void;
    text: string;
}

export const BackButton: FC<IBackButton> = ({ onClick, text }) => {
    return (
        <Box onClick={() => onClick()} sx={{ cursor: 'pointer' }}>
            <Stack flexDirection={'row'} columnGap={0.5} alignItems={'center'}>
                <Svg icon component={ArrowBackIcon} width={24} height={24} />
                <Title text={text} />
            </Stack>
        </Box>
    );
};
