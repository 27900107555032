import { FC } from 'react';
import { Stack } from '@mui/material';
import { Title } from 'components/page/advertisement/detail/components/mainContent/description/partials/title/Title';
import { TextBox } from 'components/page/advertisement/detail/components/mainContent/description/partials/textBox/TextBox';

interface IDescription {
    text: string;
}

export const Description: FC<IDescription> = ({ text }) => {
    return (
        <>
            <Stack mb={2}>
                <Title />
            </Stack>
            <TextBox text={text} />
        </>
    );
};
