import React, { FC } from 'react';
import { Box, Grid } from '@mui/material';
import { FormikProps, Form } from 'formik';
import { Button } from 'modules/theme/components/button/Button';
import { IOption } from 'modules/theme/components/fields/radioBox/interfaces/IOption';
import { IReportAdvertisement } from 'components/page/advertisement/detail/interfaces/reportAdvertisement/IReportAdvertisement';
import FirstStep from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/common/content/partials/reportForm/mobile/partials/firstStep/FirstStep';
import SecondStep from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/common/content/partials/reportForm/mobile/partials/secondStep/SecondStep';

interface IReportForm extends FormikProps<IReportAdvertisement> {
    disabled: boolean;
    options: IOption[];
    step: number;
    setStep: (step: number) => void;
}

export const ReportForm: FC<IReportForm> = ({
    errors,
    values,
    touched,
    disabled,
    handleSubmit,
    setFieldValue,
    options,
    step,
    setStep,
}) => {
    const steps = [
        <FirstStep
            key={'firstStep'}
            values={values}
            options={options}
            setFieldValue={setFieldValue}
            disabled={disabled}
        />,
        <SecondStep
            key={'secondStep'}
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            disabled={disabled}
        />,
    ];

    return (
        <Grid container display="flex" direction="column">
            <Form noValidate autoComplete="off">
                {steps[step]}
            </Form>
            <Grid item mb={2}>
                <Box
                    display={'flex'}
                    width={'100%'}
                    justifyContent={'flex-end'}
                >
                    {step === steps.length - 1 ? (
                        <Button
                            onClick={() => handleSubmit()}
                            type="submit"
                            disabled={disabled}
                            text="Odoslať"
                        />
                    ) : (
                        <Button
                            type="button"
                            disabled={disabled}
                            text="Pokračovať"
                            onClick={() => {
                                setStep(step + 1);
                            }}
                        />
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};
