import { FC } from 'react';
import { useTheme } from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';
import Icon from 'modules/theme/components/icons/module/AlbertPlus.svg';
import { PromoBanner } from 'components/page/advertisement/detail/components/mainContent/promoBanner/common/PromoBanner';

export const LandVerificationPromoBanner: FC = () => {
    const { palette } = useTheme();

    return (
        <PromoBanner
            icon={Icon}
            text="Odhaľte výhody a riziká tohto pozemku"
            ctaSubpageHref="trhove-reporty/marketplace/overovanie-pozemkov"
            iconBgColor={palette.identityBasicGreen.main}
            ctaText={(
                <Text underLine variant='body2' noWrap>
                    Zistiť viac
                </Text>
            )}
        />
    );
};
