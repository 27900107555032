import {FC} from 'react';
import {Stack} from '@mui/material';
import {Svg} from 'modules/theme/components/svg/Svg';
import {Text} from 'modules/theme/components/text/Text';
import Icon from 'modules/theme/components/icons/basic/ArrowBack.svg';
import Link from 'components/link/Link';
import { IBreadcrumb as IBreadcrumbFE } from 'components/breadcrumbs/interfaces/IBreadcrumb';

interface IBreadcrumb {
    breadcrumb: IBreadcrumbFE;
}

export const Breadcrumb: FC<IBreadcrumb> = ({breadcrumb}) => {

    return <Stack direction="row" alignItems="center" gap={{xs: .5, md: 1}} py={{xs: 1, md: 0}}>
        <Svg icon component={Icon} width={24}/>

        <Link href={breadcrumb.url || ''}>
            <Text variant="body2" mobileVariant="label1" underLine>
                {breadcrumb.title}
            </Text>
        </Link>

    </Stack>;
};
