import {FC, ReactNode} from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { IGroup } from 'components/gallery/interfaces/group/IGroup';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';
import { useGalleryItems } from 'components/gallery/hooks/useGalleryItems';
import { GalleryDialog } from 'components/gallery/components/partials/dialog/Dialog';
import { ImageGallery } from 'components/gallery/components/partials/imageGallery/ImageGallery';

export interface IProps {
    title: string;
    isOpen: boolean;
    groups: IGroup[];
    onClose: () => void;
    activeItemIndex?: number;
    activeGroupKey: EGalleryGroupKey;
    gallerySideContent?: ReactNode,
    isFullscreenGalleryOpen: boolean;
    onFullscreenGalleryClose: () => void;
    onActiveItemIndexChanged: (index: number) => void;
    onActiveGroupKeyChanged: (index: EGalleryGroupKey) => void;
}

export const Gallery: FC<IProps> = ({
    title,
    isOpen,
    onClose,
    groups,
    activeGroupKey,
    activeItemIndex,
    gallerySideContent,
    isFullscreenGalleryOpen,
    onActiveGroupKeyChanged,
    onFullscreenGalleryClose,
    onActiveItemIndexChanged,
}) => {
    const isActivePhotosGroup = activeGroupKey === EGalleryGroupKey.PHOTOS;

    const { activeGalleryItems, activeItems } = useGalleryItems(
        isFullscreenGalleryOpen,
        activeGroupKey,
        groups,
    );

    return <>
        <GalleryDialog
            title={title}
            isOpen={isOpen}
            groups={groups}
            onClose={onClose}
            activeItems={activeItems}
            activeGroupKey={activeGroupKey}
            gallerySideContent={gallerySideContent}
            isFullscreenGalleryOpen={isFullscreenGalleryOpen}
            onActiveGroupKeyChanged={onActiveGroupKeyChanged}
            onActiveItemIndexChanged={onActiveItemIndexChanged}
        />

        {isFullscreenGalleryOpen &&
            <ImageGallery
                activeItemIndex={activeItemIndex}
                activeGalleryItems={activeGalleryItems}
                isActivePhotosGroup={isActivePhotosGroup}
                onFullscreenGalleryClose={onFullscreenGalleryClose}
            />
        }
    </>;
};
