import { ILocation } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/location/ILocation';
import { IGtmLocation } from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/partials/location/IGtmLocation';

export const mapILocationToIGtmLocation = (
    dto: ILocation
): IGtmLocation => ({
    ...(!!dto.country && ({country: dto.country})),
    ...(!!dto.county && ({county: dto.county})),
    ...(!!dto.district && ({district: dto.district})),
    ...(!!dto.city && ({city: dto.city})),
});
