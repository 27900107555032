import React, { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

interface IPhoneNumber {
    phoneNumber: string;
}

export const PhoneNumber: FC<IPhoneNumber> = ({ phoneNumber }) => {
    return (
        <Text variant={'body1'} semibold>
            {phoneNumber}
        </Text>
    );
};
