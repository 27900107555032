import { FC } from 'react';
import { Desktop, Mobile } from 'modules/theme/components/responsive';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';
import { IPhoto } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/media/partials/photo/IPhoto';
import { MobileImage } from 'components/page/advertisement/detail/components/common/photos/partials/mainPhoto/partials/imageContent/mobile/MobileImage';
import { DesktopImage } from 'components/page/advertisement/detail/components/common/photos/partials/mainPhoto/partials/imageContent/desktop/DesktopImage';

interface IImageContent {
    alt?: string;
    image: IPhoto;
    isBordered: boolean;
    openGallery: (groupKey?: EGalleryGroupKey, itemIndex?: number) => void;
    isPlentyOfPhotos?: boolean
}

export const ImageContent: FC<IImageContent> = ({
    alt,
    image,
    isBordered,
    openGallery,
    isPlentyOfPhotos
}) => {
    return (
        <>
            <Mobile>
                <MobileImage
                    alt={alt}
                    image={image}
                    isBordered={false}
                    openGallery={openGallery}
                />
            </Mobile>
            <Desktop>
                <DesktopImage
                    alt={alt}
                    image={image}
                    isBordered={isBordered}
                    openGallery={openGallery}
                    isPlentyOfPhotos={isPlentyOfPhotos}
                />
            </Desktop>
        </>
    );
};
