import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';

interface ITitle {
    title: string;
}

export const Title: FC<ITitle> = ({title}) => {
    return <Text
        component="h1"
        variant="h3"
        mobileVariant="h4"
        semibold
    >
        {title}
    </Text>;
};
