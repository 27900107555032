import { FC } from 'react';
import { Formik, FormikProps } from 'formik';
import useNotification from 'modules/notification/useNotification';
import { IOption } from 'modules/theme/components/fields/radioBox/interfaces/IOption';
import { useReportAdvertisement } from 'components/page/advertisement/detail/hooks/reportAdvertisement/useReportAdvertisement';
import { AdvertisementReportTypeEnum } from 'components/page/advertisement/detail/enums/reportAdvertisement/AdvertisementReportTypeEnum';
import { IReportAdvertisement } from 'components/page/advertisement/detail/interfaces/reportAdvertisement/IReportAdvertisement';
import { getReportAdvertisementValidationSchema } from 'components/page/advertisement/detail/helpers/reportAdvertisement/GetReportAdvertisementValidationSchema';
import { ReportForm as MobileReportForm } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/common/content/partials/reportForm/mobile/ReportForm';
import { ReportForm as DesktopReportForm } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/common/content/partials/reportForm/desktop/ReportForm';

interface IContent {
    id: string;
    onClose: () => void;
    isMobile?: boolean;
    step?: number;
    setStep?: (step: number) => void;
}

export const Content: FC<IContent> = ({
    id,
    onClose,
    step,
    setStep,
    isMobile,
}) => {
    const { onSend, isLoading } = useReportAdvertisement(id);
    const { success: showSuccessFlashMessage, error: showErrorFlashMessage } =
        useNotification();

    const options: IOption[] = [
        {
            value: AdvertisementReportTypeEnum.WRONG,
            name: 'Zlý inzerát (nevhodný obsah, podvod)',
        },
        {
            value: AdvertisementReportTypeEnum.OUT_OF_DATE,
            name: 'Neaktuálny inzerát',
        },
        {
            value: AdvertisementReportTypeEnum.WRONG_CATEGORY,
            name: 'Nesprávna kategória',
        },
        { value: AdvertisementReportTypeEnum.OTHER, name: 'Iné' },
    ];

    const initFormValues: IReportAdvertisement = {
        type: AdvertisementReportTypeEnum[options[0].value.toUpperCase()],
        text: '',
        recaptchaToken: '',
    };

    return (
        <>
            <Formik
                initialValues={initFormValues}
                initialTouched={{
                    type: false,
                    text: false,
                    recaptchaToken: false,
                }}
                validationSchema={getReportAdvertisementValidationSchema()}
                onSubmit={(values: IReportAdvertisement, { resetForm }) => {
                    onSend(values).then((isSentSuccessfully) => {
                        if (isSentSuccessfully) {
                            showSuccessFlashMessage(
                                'Nahlásenie chyby bolo odoslané'
                            );
                            resetForm({ values: initFormValues });
                            onClose();
                        } else {
                            showErrorFlashMessage(
                                'Chybu sa nepodarilo nahlásiť'
                            );
                        }
                    });
                }}
            >
                {(formikProps: FormikProps<IReportAdvertisement>) =>
                    isMobile ? (
                        <MobileReportForm
                            disabled={isLoading}
                            {...formikProps}
                            options={options}
                            step={step}
                            setStep={setStep}
                        />
                    ) : (
                        <DesktopReportForm
                            disabled={isLoading}
                            {...formikProps}
                            options={options}
                        />
                    )
                }
            </Formik>
        </>
    );
};
