import { FC } from 'react';
import { Link } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import {
    getDevProjectDetailUrl
} from 'modules/api/clients/advertisement/common/helpers/devProjectUrl/getDevProjectDetailUrl';

interface IShowProjectButton {
    projectId: string;
    slug: string;
}

export const ShowProjectButton: FC<IShowProjectButton> = ({
    projectId,
    slug,
}) => {
    return (
        <Link href={getDevProjectDetailUrl(projectId, slug)}>
            <Text variant="body2" noWrap underLine>
                Zobraziť celý projekt
            </Text>
        </Link>
    );
};
