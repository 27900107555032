import { FC } from 'react';
import { Box, useMediaQuery, Theme } from '@mui/material';
import {
    Bagde
} from 'components/page/advertisement/detail/components/common/photos/partials/photoOverlay/partials/bottomContent/partials/badges/partials/badge/Badge';
import { ILemmatizeWordAccordingToNumber } from 'modules/lemmatize/ILemmatizeWordAccordingToNumber';
import { lemmatizeWordAccordingToNumber } from 'modules/lemmatize/lemmatizeWordAccordingToNumber';
import FloorPlanIcon from 'modules/theme/components/icons/technical/FloorPlan.svg';
import Object3DIcon from 'modules/theme/components/icons/basic/Object3D.svg';
import PhotoIcon from 'modules/theme/components/icons/basic/Photo.svg';
import VideoIcon from 'modules/theme/components/icons/basic/Video.svg';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';
import useSession from 'modules/state/app/hook/session/useSession';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import {
    getClick3dVideoEvent,
    getClickVideoEvent,
} from 'modules/gtmEvents/helpers/events/advertisement/detail/advertisementDetailEventsHelper';

interface IBadges {
    photosCount: number;
    areVideos?: boolean;
    areFloorPlans?: boolean;
    isInspection3d?: boolean;
    openGallery: (groupKey?: EGalleryGroupKey, itemIndex?: number) => void;
    advertisementId: string;
}

export const Badges: FC<IBadges> = ({
    areVideos,
    photosCount,
    openGallery,
    areFloorPlans,
    isInspection3d,
    advertisementId,
}) => {
    // TODO DODO Bage onlyIcon needed or refactor
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const { session } = useSession();
    const isAdvertiserLoggedIn = !!session;

    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();

    const lemmatizeWords: ILemmatizeWordAccordingToNumber = {
        eqZero: 'fotiek',
        eqOne: 'fotka',
        betweenTwoAndFourInclusive: 'fotky',
        moreThenFour: 'fotiek',
        withoutNumberIfZero: false
    };

    return <Box display='flex' alignItems='center' gap={1}>
        {photosCount > 0 &&
            <Bagde
                icon={PhotoIcon}
                text={`${lemmatizeWordAccordingToNumber(
                    photosCount,
                    lemmatizeWords
                )}`}
                onClick={() => {
                    openGallery(EGalleryGroupKey.PHOTOS);
                }}
            />}

        {areVideos && <Bagde
            text='Video'
            icon={VideoIcon}
            onlyIcon={isMobile}
            onClick={() => {
                gtmSendEvent(
                    getClickVideoEvent(advertisementId, isAdvertiserLoggedIn),
                );
                openGallery(EGalleryGroupKey.VIDEOS);
            }}
        />}

        {isInspection3d && <Bagde
            icon={Object3DIcon}
            text='3D obhliadka'
            onlyIcon={isMobile}
            onClick={() => {
                gtmSendEvent(
                    getClick3dVideoEvent(advertisementId, isAdvertiserLoggedIn),
                );
                openGallery(EGalleryGroupKey.INSPECTIONS_3D);
            }}
        />}

        {areFloorPlans && <Bagde
            text='Pôdorys'
            onlyIcon={isMobile}
            icon={FloorPlanIcon}
            onClick={() => {
                openGallery(EGalleryGroupKey.FLOOR_PLANS);
            }}
        />}
    </Box>;
};
