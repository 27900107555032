import {FunctionComponent, SVGProps} from 'react';

import Room1Icon from 'modules/theme/components/icons/category/Room1.svg';
import Room2Icon from 'modules/theme/components/icons/category/Room2.svg';
import Room3Icon from 'modules/theme/components/icons/category/Room3.svg';
import Room4Icon from 'modules/theme/components/icons/category/Room4.svg';
import Room5Icon from 'modules/theme/components/icons/category/Room5.svg';
import StudioIcon from 'modules/theme/components/icons/category/Studio.svg';
import LandOutlineIcon from 'modules/theme/components/icons/category/LandOutline.svg';
import HouseOutlineIcon from 'modules/theme/components/icons/category/HouseOutline.svg';
import CabinOutlineIcon from 'modules/theme/components/icons/category/CabinOutline.svg';
import SpaceOutlineIcon from 'modules/theme/components/icons/category/SpaceOutline.svg';
import ObjectOutlineIcon from 'modules/theme/components/icons/category/ObjectOutline.svg';


import {ECategoriesLabel} from 'components/page/advertisement/detail/enums/heading/category/ECategoriesLabel';

export const HeadingCategoryIconRecord: Record<ECategoriesLabel, FunctionComponent<SVGProps<SVGSVGElement>>> = {
    [ECategoriesLabel.HOUSES]: HouseOutlineIcon,
    [ECategoriesLabel.COTTAGES_AND_CABINS]: CabinOutlineIcon,
    [ECategoriesLabel.LANDS]: LandOutlineIcon,
    [ECategoriesLabel.SPACES]: SpaceOutlineIcon,
    [ECategoriesLabel.OBJECTS]: ObjectOutlineIcon,

    [ECategoriesLabel.STUDIO_APARTMENT]: StudioIcon,
    [ECategoriesLabel.DOUBLE_STUDIO_APARTMENT]: StudioIcon,
    [ECategoriesLabel.ONE_ROOM_APARTMENT]: Room1Icon,
    [ECategoriesLabel.TWO_ROOM_APARTMENT]: Room2Icon,
    [ECategoriesLabel.THREE_ROOM_APARTMENT]: Room3Icon,
    [ECategoriesLabel.FOUR_ROOM_APARTMENT]: Room4Icon,
    [ECategoriesLabel.FIVE_PLUS_ROOM_APARTMENT]: Room5Icon,
    [ECategoriesLabel.HOLIDAY_APARTMENT]: Room5Icon,
    [ECategoriesLabel.LOFT]: Room5Icon,
    [ECategoriesLabel.MAISONETTE]: Room5Icon,
    [ECategoriesLabel.OTHER_TYPE_OF_APARTMENT]: Room5Icon,
};
