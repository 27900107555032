import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

export const Title: FC = () => {
    return (
        <Text
            component="h3"
            variant="h6"
            noWrap
            semibold
        >
            Popis nehnuteľnosti
        </Text>
    );
};
