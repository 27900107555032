import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { Header } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/mobile/partials/header/Header';
import { Content } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/common/content/Content';

interface IDesktopModal {
    onClose: () => void;
    id: string;
}

const MobileModal: FC<IDesktopModal> = ({ onClose, id }) => {
    const [step, setStep] = useState<number>(0);

    const goBack = () => {
        const newStepIndex = step - 1;
        if (newStepIndex >= 0) {
            setStep(newStepIndex);
        }
    };

    return (
        <>
            <Box mb={3}>
                <Header
                    onClick={onClose}
                    isLastStep={step === 0}
                    onBackClick={() => goBack()}
                />
            </Box>
            <Box>
                <Content
                    id={id}
                    onClose={onClose}
                    setStep={setStep}
                    step={step}
                    isMobile
                />
            </Box>
        </>
    );
};

export default MobileModal;
