import { FC } from 'react';
import { Stack } from '@mui/material';
import { useAdvertiserPhoneNumber } from 'components/page/advertisement/detail/hooks/advertiser/useAdvertiserPhoneNumber';
import { ShowPhoneButton } from 'components/page/advertisement/detail/components/common/advertiser/partials/desktopStickyOld/partials/phone/partials/ShowPhoneButton';
import { CopyToClipboardButton } from 'components/page/advertisement/detail/components/common/advertiser/partials/desktopStickyOld/partials/phone/partials/CopyToClipboardButton';

interface IPhone {
    phoneNum?: string;
    isMobile: boolean;
    advertiserId: string;
    advertisementId: string;
    onShowButtonClick: () => void;
    onCopyPhoneNumberButtonClick: () => void;
}

export const Phone: FC<IPhone> = ({
    advertiserId,
    phoneNum,
    onShowButtonClick,
    onCopyPhoneNumberButtonClick,
}) => {
    const { phoneNumber, fetchPhoneNumber } =
        useAdvertiserPhoneNumber(advertiserId);

    const isAfterFetch = !!phoneNumber;
    const isThereAphoneNumber = phoneNum && phoneNum.length;
    const abbreviatedPhoneNumber = isThereAphoneNumber ? phoneNum.substring(0, 5) + ' ...' : ' ';

    const finalNumber = phoneNumber
        ? phoneNumber
        : abbreviatedPhoneNumber;


    const onShowPhoneClick = () => {
        if (!isAfterFetch) {
            fetchPhoneNumber('token');
            onShowButtonClick();
        }
    };

    return <Stack direction="row" alignItems="center" gap={1} justifyContent={'center'}>
        {isAfterFetch && <CopyToClipboardButton
            phoneNumber={finalNumber}
            onClick={onCopyPhoneNumberButtonClick}
        />}

        {!isAfterFetch && <ShowPhoneButton
            phoneNumber={finalNumber}
            onClick={onShowPhoneClick}
        />}
    </Stack>;
};
