import React, { FC } from 'react';
import { Box } from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { IOption } from 'modules/theme/components/fields/radioBox/interfaces/IOption';
import { Select } from 'modules/theme/components/fields/select/Select';
import { TextArea } from 'modules/theme/components/fields/textArea/TextArea';
import { IContactAdvertiser } from 'components/page/advertisement/detail/interfaces/advertiser/IContactAdvertiser';
import { Title } from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/partials/contactForm/mobile/partials/firstStep/partials/title/Title';
import { Message } from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/common/content/partials/contactForm/mobile/partials/firstStep/partials/message/Message';

interface IFirstStep {
    values: IContactAdvertiser;
    options: IOption[];
    setFieldValue: (
        field: string,
        value: string,
        shouldValidate?: boolean
    ) => void;
    setFieldTouched: (
        field: string,
        isTouched?: boolean,
        shouldValidate?: boolean
    ) => void;
    touched: FormikTouched<IContactAdvertiser>;
    errors: FormikErrors<IContactAdvertiser>;
    disabled?: boolean;
}

const FirstStep: FC<IFirstStep> = ({
    values,
    options,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    disabled,
}) => {
    return (
        <>
            <Box mb={2}>
                <Title />
            </Box>
            <Box mb={3}>
                <Message />
            </Box>
            <Box mb={1.5}>
                <Select
                    options={options}
                    disabled={disabled}
                    selectedValue={values.type}
                    placeholder={'Čo sa chcete opýtať?'}
                    errorMsg={touched.type && errors.type}
                    onChange={(value) => setFieldValue('type', value)}
                    onBlur={() => setFieldTouched('type')}
                />
            </Box>
            <Box mb={1.5}>
                <TextArea
                    rows={5}
                    disabled={disabled}
                    value={values.text}
                    errMsg={touched.text && errors.text}
                    onChange={(value) => setFieldValue('text', value)}
                    onBlur={() => setFieldTouched('text')}
                />
            </Box>
        </>
    );
};

export default FirstStep;
