import { FC } from 'react';
import { IItem } from 'components/gallery/interfaces/group/partials/item/IItem';
import {
    GalleryElement
} from 'components/gallery/components/partials/dialog/partials/items/partials/element/Element';

interface IGalleryListItem {
    listItem: IItem;
    listItemIndex: number;
    isFullscreenGalleryOpen: boolean;
    onActiveItemIndexChanged: (index: number) => void;
}

export const GalleryListItem: FC<IGalleryListItem> = ({
    listItem,
    listItemIndex,
    isFullscreenGalleryOpen,
    onActiveItemIndexChanged,
}) => {

    return <GalleryElement
        element={listItem.element}
        isFullscreen={isFullscreenGalleryOpen}
        onClick={() => {
            onActiveItemIndexChanged(listItemIndex);
        }}
    />;
};
