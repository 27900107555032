import {FC} from 'react';
import Link from 'components/link/Link';
import {Chip} from 'modules/theme/components/chip/Chip';

interface IBreadcrumbItem {
    text: string;
    href?: string;
    isLast?: boolean;
}

export const BreadcrumbItem: FC<IBreadcrumbItem> = ({href, text, isLast}) => {

    return <Link href={href} disabled={isLast}>
        <Chip text={text} small/>
    </Link>;
};