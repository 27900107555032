import { useEffect, useState } from 'react';

interface IReturn {
    isVisible: boolean;
}

export const useTopPromoNavbar = (): IReturn => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll',listenToScroll);
        return () =>
            window.removeEventListener('scroll', listenToScroll);
    }, []);

    const listenToScroll = () => {
        const container = document.getElementById('main-detail-content');
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;

        const additionalPixels = 300;
        const containerHeight = container?.getBoundingClientRect().height;

        if (winScroll > (containerHeight + additionalPixels)) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    return {
        isVisible,
    };
};