import { FC } from 'react';
import { CloseButton } from 'components/page/advertisement/detail/components/common/advertiser/common/contactModal/partials/mobile/partials/header/partials/closeButton/CloseButton';
import { BackButton } from 'components/page/advertisement/detail/components/common/advertiser/common/backButton/BackButton';

interface IHeader {
    onClick: () => void;
    onBackClick: () => void;
    isLastStep: boolean;
    isOpenedFromAgentContactModal?: boolean;
}

export const Header: FC<IHeader> = ({
    onClick,
    isLastStep,
    onBackClick,
    isOpenedFromAgentContactModal,
}) => {
    return isLastStep ? (
        <CloseButton
            onClick={onClick}
            isOpenedFromAgentContactModal={isOpenedFromAgentContactModal}
        />
    ) : (
        <BackButton onClick={onBackClick} text={'Späť'} />
    );
};
