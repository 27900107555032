import { FC } from 'react';
import { Box } from '@mui/material';
import { Figure } from 'modules/theme/components/assets/blankPlaceholder/Figure';

export const NoPhotos: FC = () => {

    return (
        <Box overflow={'visible'}>
            <Box
                position={'relative'}
                maxHeight={500}
                // minHeight={350}
                height={{md: 'calc(100vh - 290px)'}}
                width={'100%'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                borderRadius={(theme) => ({ xs: 0, md: theme.spacing(2) })}
                bgcolor={(theme) => theme.palette.backgroundSecondary.main}
                sx={(theme) => ({
                    [theme.breakpoints.down('md')]: {
                        minHeight: 292
                    },
                })}
            >
                <Box display={{xs: 'block', md: 'none'}}>
                    <Figure width={80}/>
                </Box>
                <Box display={{xs: 'none', md: 'block'}}>
                    <Figure width={128}/>
                </Box>
            </Box>
        </Box>
    );
};

