import { FC } from 'react';
import { Button } from 'modules/theme/components/button/Button';
import FlagIcon from 'modules/theme/components/icons/basic/Flag.svg';

interface IReportButton {
    onClick: () => void;
    text: string;
}

export const ReportButton: FC<IReportButton> = ({ onClick, text }) => {
    return (
        <Button text={text} startIcon={FlagIcon} onClick={onClick} textBtn />
    );
};
