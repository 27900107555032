import {
    IAdvertisementDetail
} from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/IAdvertisementDetail';
import {
    IAdvertisementGtmItem
} from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/IAdvertisementGtmItem';
import { mapILocationToIGtmLocation } from 'modules/api/clients/advertisement/detail/helpers/mappers/advertisementDetail/location/mapILocationToIGtmLocation';
import { mapIAdvertiserToIGtmAdvertiser } from 'modules/api/clients/advertisement/detail/helpers/mappers/advertisementDetail/advertiser/mapIAdvertiserToIGtmAdvertiser';
import { IVideo } from 'modules/api/clients/advertisement/common/interfaces/advertisementGtmItem/partials/video/IVideo';

export const mapIAdvertisementDetailToGtmItemMapper = (advertisement: IAdvertisementDetail): IAdvertisementGtmItem => {
    let photoUrl;
    if (advertisement.media.photos.length) {
        photoUrl = advertisement.media.photos[0];
    }

    let videos: IVideo[] = [];
    if (advertisement.media.videos.length) {
        videos = advertisement.media.videos.map((video) => ({
            type: video.type,
            url: video.url,
        }));
    }

    return {
        id: advertisement.id,
        title: advertisement.title,
        photoUrl,
        location: mapILocationToIGtmLocation(advertisement.location),
        advertiser: mapIAdvertiserToIGtmAdvertiser(advertisement.advertiser),
        price: {
            price: advertisement.parameters.price.price,
            unitPrice: advertisement.parameters.price.unitPrice,
            priceValue: advertisement.parameters.price.priceValue,
            hasPriceOption: advertisement.parameters.price.hasPriceOption,
        },
        category: {
            mainValue: advertisement.parameters.category.mainValue,
            subValue: advertisement.parameters.category.subValue,
        },
        videos,
        transaction: advertisement.parameters.transaction,
        realEstateState: advertisement.parameters.realEstateState,
        devProjectId: advertisement.devProject?.id,
        isTop: advertisement.flags.isTop,
        isPremium: advertisement.flags.isPremium,
    };
};
