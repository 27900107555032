import {FC} from 'react';
import {
    BreadcrumbItem
} from 'components/page/advertisement/detail/components/mainContent/breadcrumbs/desktop/partials/BreadcrumbItem';
import {Stack} from '@mui/material';
import { IBreadcrumb as IBreadcrumbFE } from 'components/breadcrumbs/interfaces/IBreadcrumb';

interface IBreadcrumbs {
    breadcrumbs: IBreadcrumbFE[];
}

export const Breadcrumbs: FC<IBreadcrumbs> = ({breadcrumbs}) => {

    return (
        <Stack direction="row" gap={0.5}>
            {breadcrumbs.map(({title, url}, index) => (
                <BreadcrumbItem
                    key={index}
                    href={url}
                    text={title}
                    isLast={!url}
                />
            ))}
        </Stack>
    );
};
