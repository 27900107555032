import React, { FC } from 'react';
import { Box, Container } from '@mui/material';
import {Button} from 'modules/theme/components/button/Button';

import {
    AdvertisementInfo
} from 'components/page/advertisement/detail/components/mainContent/topPromoNavbar/partials/advertisementInfo/AdvertisementInfo';
import {ESubcategory} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';

interface ITopPromoNavbar {
    price: string;
    location?: string;
    category: ESubcategory;
}

export const TopPromoNavbar: FC<ITopPromoNavbar> = ({
    price,
    location,
    category,
}) => {
    const scrollToContactAdvertiserForm = (): void => {
        const container = document.getElementById('contact-advertiser-form');

        container.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    };

    return <Box
        top={0}
        zIndex={1700}
        paddingY={2}
        position='sticky'
        borderBottom={(theme) => `1px solid ${
            theme.palette.dividerTransparent
        }`}
        boxShadow='0px 2px 4px 0px rgba(0, 0, 0, 0.08)'
        bgcolor={(theme) => theme.palette.backgroundPrimary.main}
    >
        <Container sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }}>
            <AdvertisementInfo
                price={price}
                location={location}
                category={category}
            />

            <Button
                text={'Kontaktovať predajcu'}
                onClick={scrollToContactAdvertiserForm}
            />
        </Container>
    </Box>;
};