import { FC } from 'react';
import { Box } from '@mui/material';
import { ILocation } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/advertiser/partials/common/location/ILocation';
import { AgencyName } from 'components/page/advertisement/detail/components/common/advertiser/common/agencyProfile/partials/agencyInfo/agencyName/AgencyName';
import { AgencyAddressText } from 'components/page/advertisement/detail/components/common/advertiser/common/agencyProfile/partials/agencyInfo/agencyAddressText/AgencyAddressText';

interface IAdvertiserName {
    name?: string;
    location?: ILocation;
}

export const AgencyInfo: FC<IAdvertiserName> = ({ name, location }) => {
    return (
        <Box>
            <Box mb={0.5}>
                <AgencyName name={name} />
            </Box>
            {location?.parts.map((part, index) => (
                <AgencyAddressText key={index} addressPart={part} />
            ))}
        </Box>
    );
};
