import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

export const Title: FC = () => {
    return (
        <Text
            component="h2"
            variant="h4"
            semibold
        >
            Predávajúci
        </Text>
    );
};
