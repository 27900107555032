import { FC } from 'react';
import { Box } from '@mui/material';
import { Header } from 'components/page/advertisement/detail/components/common/advertiser/common/phoneNumbersModal/partials/mobile/partials/header/Header';
import { Content } from 'components/page/advertisement/detail/components/common/advertiser/common/phoneNumbersModal/partials/mobile/partials/content/Contet';

interface IModal {
    onClose: () => void;
    phoneNumber: string;
    isOpenedFromAgentContactModal?: boolean;
    onPhoneClick?: (phone: string) => void;
}

export const Modal: FC<IModal> = ({
    onClose,
    phoneNumber,
    isOpenedFromAgentContactModal,
    onPhoneClick,
}) => {
    return (
        <>
            <Box mb={2}>
                <Header
                    onClick={onClose}
                    isOpenedFromAgentContactModal={
                        isOpenedFromAgentContactModal
                    }
                />
            </Box>
            <Box>
                <Content
                    phoneNumber={phoneNumber}
                    onClick={onPhoneClick}
                />
            </Box>
        </>
    );
};
