import {FC} from 'react';
import {Option} from 'components/page/advertisement/detail/components/common/advertiser/partials/desktopStickyOld/partials/buttonsBox/partials/switchButtons/partials/Option';
import {Wrapper} from 'components/page/advertisement/detail/components/common/advertiser/partials/desktopStickyOld/partials/buttonsBox/partials/switchButtons/partials/Wrapper';
import {IOption} from 'components/page/advertisement/detail/components/common/advertiser/partials/desktopStickyOld/partials/buttonsBox/partials/switchButtons/interfaces/IOption';

interface ISwitchFilter {
    options: IOption[];
    uniformWidth?: boolean;
    activeOptionValue: string;
    onChange: (value: string, index: number) => void;
}

export const SwitchButtons: FC<ISwitchFilter> = ({
    options,
    onChange,
    activeOptionValue,
    uniformWidth = false,
}) => {
    return (
        <Wrapper>
            <>
                {options.map((option, index) => (
                    <Option
                        key={option.value}
                        name={option.name}
                        onClick={(value) => {
                            onChange(value, index);
                        }}
                        value={option.value}
                        uniformWidth={uniformWidth}
                        disabled={option.isDisabled}
                        totalOptionsCount={options.length}
                        isActive={activeOptionValue === option.value}
                    />
                ))}
            </>
        </Wrapper>
    );
};
