import { FC, useMemo, useCallback } from 'react';
import { useMediaQuery, Theme } from '@mui/material';
import ArrowLeftIcon from 'modules/theme/components/icons/basic/KeyboardArrowLeft.svg';
import ArrowRightIcon from 'modules/theme/components/icons/basic/KeyboardArrowRight.svg';
import ImageGalleryComponent, { ReactImageGalleryItem } from 'react-image-gallery';
import {
    NavArrow,
} from 'components/gallery/components/partials/imageGallery/partials/navArrow/NavArrow';
import {
    GalleryWrapper
} from 'components/gallery/components/partials/imageGallery/partials/wrapper/GalleryWrapper';
import { useScreenHeight } from 'components/page/advertisement/detail/hooks/gallery/useScreenHeight';
import {
    CloseButton
} from 'components/gallery/components/partials/imageGallery/partials/closeButton/CloseButton';

interface IImageGallery {
    activeItemIndex: number;
    isActivePhotosGroup: boolean;
    onFullscreenGalleryClose: () => void;
    activeGalleryItems: ReactImageGalleryItem[];
}

export const ImageGallery: FC<IImageGallery> = ({
    activeItemIndex,
    activeGalleryItems,
    isActivePhotosGroup,
    onFullscreenGalleryClose,
}) => {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const { screenHeight } = useScreenHeight();

    const isScreenNarrow = useMemo(() => {
        return screenHeight <= 600;
    }, [screenHeight]);

    const galleryRef = useCallback((reactImageGallery: ImageGalleryComponent): void => {
        if (reactImageGallery) {
            reactImageGallery.slideToIndex(activeItemIndex);
            reactImageGallery['toggleFullScreen']();
        }
    }, [activeItemIndex]);

    return <GalleryWrapper>
        <ImageGalleryComponent
            showIndex
            ref={galleryRef}
            showNav={!isMobile}
            showPlayButton={false}
            items={activeGalleryItems}
            useBrowserFullscreen={false}
            showThumbnails={!isMobile && isActivePhotosGroup && !isScreenNarrow}
            renderFullscreenButton={() => <CloseButton onClick={onFullscreenGalleryClose}/>}
            renderLeftNav={(onClick) => <NavArrow onClick={onClick} icon={ArrowLeftIcon} direction='left'/>}
            renderRightNav={(onClick) => <NavArrow onClick={onClick} icon={ArrowRightIcon} direction='right'/>}
        />
    </GalleryWrapper>;
};