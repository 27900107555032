import React, { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

export const Description: FC = () => {
    return (
        <Text variant={'body2'} color={(palette) => palette.labelSecondary}>
            Napíšte otázku k tejto nehnuteľnosti predajcovi.
        </Text>
    );
};
