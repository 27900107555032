import {FC} from 'react';
import {Box} from '@mui/material';
import {
    BreadcrumbItem
} from 'components/page/advertisement/detail/components/mainContent/breadcrumbs/mobile/partials/BreadcrumbItem';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';

interface IBreadcrumbs {
    breadcrumbs: IBreadcrumb[];
}

export const Breadcrumbs: FC<IBreadcrumbs> = ({ breadcrumbs }) => {

    return (
        <Box display='flex' flexWrap='wrap' gap={1}>
            {breadcrumbs.map(({title, url}, index) => (
                <BreadcrumbItem
                    key={index}
                    href={url}
                    text={title}
                    isLast={!url}
                />
            ))}
        </Box>
    );
};