import React, { FC } from 'react';
import { Box, Stack, Theme, useTheme } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import CallIcon from 'modules/theme/components/icons/basic/Call.svg';
import { PhoneNumber } from 'components/page/advertisement/detail/components/common/advertiser/common/phoneNumbersModal/common/phoneNumber/PhoneNumber';
import { Title } from 'components/page/advertisement/detail/components/common/advertiser/common/phoneNumbersModal/partials/mobile/partials/content/partials/phoneCard/partials/title/Title';
import Link from 'components/link/Link';

interface IPhoneCard {
    phoneNumber: string;
    onClick?: (phone: string) => void;
}

export const PhoneCard: FC<IPhoneCard> = ({ phoneNumber, onClick }) => {
    const theme: Theme = useTheme();
    return (
        <Link
            href={`tel:${phoneNumber}`}
            noUnderline
            onClick={() => {
                if (typeof onClick === 'function') {
                    onClick(phoneNumber);
                }
            }}
        >
            <Box
                mb={2}
                padding={1.5}
                border={1}
                borderColor={theme.palette.labelPrimary.main}
                borderRadius={2}
                sx={{
                    background: theme.palette.backgroundPrimary.main,
                }}
                boxShadow={'0px 2px 4px 0px rgba(0, 0, 0, 0.08)'}
            >
                <Stack
                    flexDirection={'row'}
                    columnGap={1.5}
                    alignItems={'center'}
                >
                    <Svg component={CallIcon} width={40} height={40} />
                    <Box>
                        <PhoneNumber phoneNumber={phoneNumber} />
                        <Title />
                    </Box>
                </Stack>
            </Box>
        </Link>
    );
};
