import { FC, SVGProps, MouseEventHandler  } from 'react';
import { Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';

interface IBaseArrow {
    direction: 'left' | 'right';
    icon: FC<SVGProps<SVGSVGElement>>;
    onClick: MouseEventHandler<HTMLElement>;
}

export const NavArrow: FC<IBaseArrow> = ({direction, icon, onClick}) => {
    const isRight = direction === 'right';

    return <Box
        top='50%'
        zIndex={1}
        width={40}
        height={40}
        display='flex'
        onClick={onClick}
        alignItems='center'
        position='absolute'
        right={isRight && 48}
        borderRadius={'99px'}
        justifyContent='center'
        bgcolor={(theme) => theme.palette.labelPrimary.main}
        color={(theme) => theme.palette.labelPrimaryInverse.main}
        sx={{
            transform: 'translate(24px, -50%)',
            cursor: 'pointer'
        }}
    >
        <Svg icon component={icon} width={24}/>
    </Box>;
};