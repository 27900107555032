import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { IOption } from 'modules/theme/components/fields/radioBox/interfaces/IOption';
import { RadioBoxGroup } from 'modules/theme/components/fields/radioBox/RadioBoxGroup';
import { IReportAdvertisement } from 'components/page/advertisement/detail/interfaces/reportAdvertisement/IReportAdvertisement';
import { RadioGroupLabel } from 'components/page/advertisement/detail/components/mainContent/reportAdvertisement/partials/modal/common/content/partials/reportForm/common/RadioGroupLabel';

interface IFirstStep {
    values: IReportAdvertisement;
    options: IOption[];
    setFieldValue: (
        field: string,
        value: string,
        shouldValidate?: boolean
    ) => void;
    disabled?: boolean;
}

const FirstStep: FC<IFirstStep> = ({
    values,
    options,
    setFieldValue,
    disabled,
}) => {
    return (
        <>
            <Grid item mb={3}>
                <RadioGroupLabel />
            </Grid>
            <Grid item mb={3}>
                <RadioBoxGroup
                    options={options}
                    disabled={disabled}
                    value={values.type}
                    onChange={(value) => setFieldValue('type', value)}
                    row={false}
                />
            </Grid>
        </>
    );
};

export default FirstStep;
