import { FC } from 'react';
import { useTheme } from '@mui/material';
import { Img } from 'components/page/advertisement/detail/components/img/Img';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';
import { EImgWidth } from 'components/page/advertisement/detail/components/img/enums/EImgWidth';
import { IPhoto } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/media/partials/photo/IPhoto';

interface IMobileImage {
    alt?: string;
    image: IPhoto;
    isBordered: boolean;
    openGallery: (groupKey?: EGalleryGroupKey, itemIndex?: number) => void;
}

export const MobileImage: FC<IMobileImage> = ({
    alt,
    image,
    isBordered,
    openGallery
}) => {
    const theme = useTheme();

    return (
        <Img
            priority
            width={775}
            height={408}
            srcSet={{
                [EImgWidth.medium]: image.mediumUrl,
                [EImgWidth.large]: image.largeUrl || image.origUrl,
            }}
            style={{
                width: '100%',
                height: '100%',
                maxHeight: 408,
                objectFit: 'cover',
                borderTopRightRadius: isBordered && theme.spacing(2),
                borderBottomRightRadius: isBordered && theme.spacing(2),
            }}
            sizes={'100vw'}
            alt={alt || `advertisement-photo-${1}`}
            onClick={() => {
                openGallery(EGalleryGroupKey.PHOTOS, 0);
            }}
        />
    );
};