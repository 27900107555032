import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { Svg } from 'modules/theme/components/svg/Svg';
import RealEstateIcon from 'components/page/advertisement/detail/assets/icons/RealEstateIcon.svg';

export const InfoBox: FC = () => {
    return (
        <Stack flexDirection={'row'} columnGap={1.5} alignItems={'center'}>
            <Svg component={RealEstateIcon} width={24} height={24} />
            <Text
                variant={'label2'}
                color={(palette) => palette.labelSecondary}
            >
                Nezabudnite povedať, že voláte na ponuku, ktorú ste našli na
                Nehnuteľnosti.sk
            </Text>
        </Stack>
    );
};
