import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

interface IAdvertiserName {
    name: string;
}

export const AdvertiserName: FC<IAdvertiserName> = ({ name }) => {
    return (
        <Text variant={'h5'} semibold>
            {name}
        </Text>
    );
};
