import { FC, useEffect } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { Box, Theme } from '@mui/material';

interface IFullText {
    text: string;
}

export const HiddenText: FC<IFullText> = ({ text }) => {
    const textId = 'hidden-text-description';

    useEffect(() => {
        if (document) {
            const textElement = document.getElementById(textId);
            if (textElement) {
                textElement.innerHTML = text;
            }
        }
    }, [text]);

    return (
        <Box
            sx={{
                position: 'relative',
            }}
        >
            <Text id={textId} variant={'body2'} lineClamp={10} preWrap>
                {text}
            </Text>
            <Box
                position={'absolute'}
                sx={{
                    content: '" "',
                    background: (theme: Theme) =>
                        `linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, ${theme.palette.background.paper} 100%)`,
                }}
                left={0}
                right={0}
                bottom={0}
                top={0}
            />
        </Box>
    );
};
