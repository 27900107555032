import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';
import {Button as MuiButton, Palette} from '@mui/material';

interface IProps {
    phoneNumber: string;
    onClick?: () => void;
}

export const ShowPhoneButton: FC<IProps> = ({
    phoneNumber,
    onClick,
}) => {

    return <>
        <Text
            underLine
            semibold
            variant="h5"
            color={(palette: Palette) => palette.primary}
        >
            {phoneNumber}
        </Text>
        <MuiButton
            size={'small'}
            onClick={() => {
                if (typeof onClick === 'function') {
                    onClick();
                }
            }}
            variant={'contained'}
            sx={{
                height: '32px',
                boxShadow: 'none!important',
                textTransform: 'none',
                borderRadius: '20px',
                color: '#2D2926',
                background: 'rgba(45, 41, 38, 0.04)',
                '&:hover': {
                    backgroundColor: 'rgba(45, 41, 38, 0.04)',
                },
            }}
        >
            Zobraziť číslo
        </MuiButton>
    </>;
};
