import { useMemo } from 'react';

interface IReturn {
    isPlentyOfPhotos: boolean;
}

export const useDetailLayout = (countOfPhotos: number): IReturn => {

    const isPlentyOfPhotos = useMemo(() => {
        return countOfPhotos >= 3;
    }, [countOfPhotos]);

    return {
        isPlentyOfPhotos,
    };
};