import { FC, ReactNode, useMemo } from 'react';
import {Grid, Container, Box } from '@mui/material';
import { IGroup } from 'components/gallery/interfaces/group/IGroup';
import { EGalleryGroupKey } from 'components/gallery/enums/group/EGalleryGroupKey';
import { IItem } from 'components/gallery/interfaces/group/partials/item/IItem';
import { SwitchFilter } from 'modules/theme/components/filter/switchFilter/SwitchFilter';
import { GalleryListItem } from 'components/gallery/components/partials/dialog/partials/items/partials/listItem/ListItem';
import { Desktop, Mobile } from 'modules/theme/components/responsive';

interface IGalleryItems {
    groups: IGroup[];
    activeItems: IItem[];
    sideContent?: ReactNode,
    activeGroupKey: EGalleryGroupKey;
    isFullscreenGalleryOpen: boolean;
    onActiveItemIndexChanged: (index: number) => void;
    onActiveGroupKeyChanged: (index: EGalleryGroupKey) => void;
}

export const GalleryItems: FC<IGalleryItems> = ({
    groups,
    activeItems,
    sideContent,
    activeGroupKey,
    isFullscreenGalleryOpen,
    onActiveItemIndexChanged,
    onActiveGroupKeyChanged,
}) => {

    const hasSideContent = !!sideContent;

    const activeOptionValue = useMemo(() => {
        return groups.find((group) => group.key === activeGroupKey)?.key;
    }, [groups, activeGroupKey]);

    return <>
        <Grid
            container
            spacing={2}
            paddingX={2}
            overflow='auto'
            paddingBottom={12}
            justifyContent="center"
            pt={{ xs: 1, md: 2 }}
        >
            {groups?.length > 1 && (
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Grid item xs="auto" justifyContent="center">
                        <SwitchFilter
                            options={groups.map(group => ({
                                name: group.name,
                                value: group.key,
                                isDisabled: false,
                            }))}
                            uniformWidth={false}
                            activeOptionValue={activeOptionValue}
                            onChange={(groupName) => {
                                onActiveItemIndexChanged(undefined);
                                onActiveGroupKeyChanged(EGalleryGroupKey[groupName]);
                            }}
                        />
                    </Grid>
                </Grid>
            )}
            <Container maxWidth={'lg'} disableGutters sx={{ paddingLeft: 2, paddingTop: 2 }}>
                <Box
                    display={'flex'}
                    width={'100%'}
                    gap={2}
                >
                    <Box width={'100%'}>
                        {activeItems.map((item, index) => (
                            <Grid item key={`item-${index}`} xs={12} container justifyContent="center">
                                <GalleryListItem
                                    listItem={item}
                                    listItemIndex={index}
                                    isFullscreenGalleryOpen={isFullscreenGalleryOpen}
                                    onActiveItemIndexChanged={onActiveItemIndexChanged}
                                />
                            </Grid>
                        ))}
                    </Box>
                    <Desktop display='contents'>
                        {hasSideContent &&
                            <Box minWidth={378}>
                                {sideContent}
                            </Box>
                        }
                    </Desktop>
                </Box>
            </Container>
        </Grid>

        <Mobile display='contents'>
            <>{sideContent}</>
        </Mobile>
    </>;
};
