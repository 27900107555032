import React, { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

export const Message: FC = () => {
    return (
        <Text variant={'label1'} color={(palette) => palette.labelSecondary}>
            Vaša správa príde predajcovi na jeho e-mail.
        </Text>
    );
};
