import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { Box, IconButton, Theme } from '@mui/material';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';
import { Svg } from 'modules/theme/components/svg/Svg';

interface IHeader {
    title: string;
    onClick: () => void;
}

export const Header: FC<IHeader> = ({ title, onClick }) => {
    return (
        <>
            <Box mb={2}>
                <IconButton
                    onClick={onClick}
                    size="small"
                    color={'inherit'}
                    sx={{
                        background: 'transparent',
                        color: (theme: Theme) =>
                            `${theme.palette.labelPrimary.main}`,
                    }}
                >
                    <Svg icon component={CloseIcon} />
                </IconButton>
            </Box>

            <Text variant={'h5'} semibold mb={1}>
                {title}
            </Text>
        </>
    );
};
