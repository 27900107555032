import { Box, useTheme } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { Img } from 'components/page/advertisement/detail/components/img/Img';
import { EImgWidth } from 'components/page/advertisement/detail/components/img/enums/EImgWidth';
import { IPhoto as IPhotoData } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/media/partials/photo/IPhoto';

const maxHeight = 500;
const minHeight = 350;
const middleMargin = 4;
interface IPhoto {
    alt: string;
    image: IPhotoData;
    imageIndex: number;
    onClick: () => void;
    hasThird?: boolean;
}

export const Photo: FC<IPhoto> = ({ alt, image, onClick, imageIndex, hasThird }) => {
    const theme = useTheme();
    const [windowHeight, setWindowHeight] = useState(0);

    const isFirstImage = imageIndex === 1;
    const isOnlyImage = isFirstImage && !hasThird;

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setWindowHeight(window.innerHeight);
        }

        const handleWindowResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const calculatedHeight = useMemo(() => {
        const bottomOffset = 290;
        const y = windowHeight - bottomOffset;

        if (isOnlyImage) {
            return y;
        }

        return y / 2 - middleMargin;
    }, [windowHeight, isOnlyImage]);

    return (
        <Box overflow={'visible'}>
            <Box
                position={'relative'}
                maxHeight={isOnlyImage ? maxHeight : maxHeight / 2 - middleMargin}
                minHeight={isOnlyImage ? minHeight : minHeight / 2 - middleMargin}
                height={calculatedHeight}
            >
                <Box>
                    <Img
                        fill
                        priority
                        alt={alt}
                        srcSet={{
                            [EImgWidth.medium]: image.mediumUrl,
                        }}
                        onClick={onClick}
                        style={{
                            objectFit: 'cover',
                            borderTopRightRadius: isFirstImage && theme.spacing(2),
                            borderBottomRightRadius: (!isFirstImage || !hasThird) && theme.spacing(2),
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};
