import {FC} from 'react';
import Image from 'components/image/Image';
import {FirmNoPhoto} from 'modules/theme/components/assets/noPhoto/FirmNoPhoto';
import {Box} from '@mui/material';

interface IFirm {
    width: number | string;
    height?: number | string;
    logoUrl?: string
}

export const Firm: FC<IFirm> = ({width, logoUrl, height}) => {
    return <Box width={width} height={height || width} position="relative">
        {logoUrl
            ? <Image fill src={logoUrl} alt="Logo realitnej agentúry" style={{
                objectFit: 'contain'
            }}/>
            : <FirmNoPhoto width={width} height={height}/>
        }
    </Box>;
};
