import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';

interface INoteToPrice {
    note?: string;
}

export const NoteToPrice: FC<INoteToPrice> = ({note}) => {
    return <Text
        variant="label2"
        color={(palette) => palette.labelSecondary}
    >
        {note}
    </Text>;
};