import { FC } from 'react';
import { Box } from '@mui/material';
import { IAttribute } from 'modules/api/clients/advertisement/detail/interfaces/advertisementDetail/partials/parameters/partials/attribute/IAttribute';
import { Header } from 'components/page/advertisement/detail/components/mainContent/detailParameters/partials/realEstateProperties/modal/partials/Header';
import { Content } from 'components/page/advertisement/detail/components/mainContent/detailParameters/partials/realEstateProperties/modal/partials/Content';

interface IAttributesModal {
    title: string;
    attributes: IAttribute[];
    onClose?: () => void;
}

export const AttributesModal: FC<IAttributesModal> = ({
    title,
    attributes,
    onClose,
}) => {
    return (
        <Box>
            <Header title={title} onClick={onClose} />
            {attributes && (
                <Box pt={2}>
                    <Content attributes={attributes} />
                </Box>
            )}
        </Box>
    );
};
