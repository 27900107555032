import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

interface IAdvertiserProfileTitle {
    title: string;
}

export const AdvertiserProfileTitle: FC<IAdvertiserProfileTitle> = ({
    title,
}) => {
    return (
        <Text variant={'body1'} noWrap underLine>
            {title}
        </Text>
    );
};
