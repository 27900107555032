import {FC, FunctionComponent, SVGProps} from 'react';
import {Wrapper} from 'modules/theme/components/listing/parameter/components/partials/wrapper/Wrapper';
import {Content} from 'modules/theme/components/listing/parameter/components/partials/content/Content';


interface IParamter {
    label: string;
    value: string;
    divider?: boolean;
    isAttribute?: boolean;
    isHighlighted?: boolean;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>
}

export const Parameter: FC<IParamter> = ({
    icon,
    label,
    value,
    divider,
    isAttribute,
    isHighlighted,
}) => {
    return <Wrapper
        divider={divider}
        isAttribute={isAttribute}
        isHighlighted={isHighlighted}
    >
        <Content icon={icon} label={label} value={value}/>
    </Wrapper>;
};
