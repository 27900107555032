import { useCallback } from 'react';
import useLoading from 'modules/state/app/hook/useLoading';
import { contactAdvertiser } from 'components/page/advertisement/detail/api/advertiser/contactAdvertiser';
import { IContactAdvertiser } from 'components/page/advertisement/detail/interfaces/advertiser/IContactAdvertiser';
import { ContactTypeRecord } from 'components/page/advertisement/detail/constants/record/advertiser/contact/ContactTypeRecord';

interface IReturn {
    isLoading: boolean;
    onSend: (data: IContactAdvertiser) => Promise<boolean>;
}

export const useContactAdvertiser = (id: string): IReturn => {
    const { start: startLoading, stop: stopLoading, isLoading } = useLoading();

    const sendReport = useCallback(
        async (data: IContactAdvertiser): Promise<boolean> => {
            try {
                startLoading();
                const sendResponse = await contactAdvertiser(
                    id,
                    {
                        text: data.text,
                        mail: data.mail,
                        phoneNumber: data.phoneNumber,
                        name: data.name,
                        type: ContactTypeRecord[data.type],
                    },
                    data.recaptchaToken
                );
                const { status } = sendResponse;
                const isSuccess = status === 201;

                stopLoading();

                return isSuccess;
            } catch (e) {
                return false;
            } finally {
                stopLoading();
            }
        },
        [startLoading, stopLoading, id]
    );

    return {
        isLoading,
        onSend: sendReport,
    };
};
