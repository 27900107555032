import { useInView } from 'react-intersection-observer';

interface IReturn {
    wasInView: boolean;
    ref: (node: Element) => void;
}

export const useWasInView = (): IReturn => {
    const { inView, ref } = useInView({ triggerOnce: true });

    return {
        wasInView: inView,
        ref,
    };
};
