import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { Box } from '@mui/material';

interface IFullText {
    onBtnClick: () => void;
    isShowMore: boolean;
}

export const ShowOrHideButton: FC<IFullText> = ({ isShowMore, onBtnClick }) => {
    return (
        <Box
            mt={2}
            role={'button'}
            onClick={() => onBtnClick()}
            sx={{ cursor: 'pointer' }}
        >
            <Text variant={'body2'} noWrap underLine>
                {isShowMore ? 'Zobraziť menej' : 'Čítať ďalej'}
            </Text>
        </Box>
    );
};
