import {FC, FunctionComponent, SVGProps} from 'react';
import {Svg} from 'modules/theme/components/svg/Svg';

interface IParameterItem {
    text: string;
    withMargin?: boolean
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const ParameterItem: FC<IParameterItem> = ({icon, text, withMargin}) => {
    return <>
        {withMargin && <>&nbsp;&nbsp;&nbsp;</>}
        <p style={{
            margin: 0,
            paddingBottom: '3px',
            display: 'inline-flex',
            verticalAlign: 'middle'
        }}>
            <Svg icon component={icon} width={16}/>
        </p>
        &nbsp;
        {text}
    </>;
};
